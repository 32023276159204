import React, { useState, useEffect } from "react";
import styles from "./JobInfoForm.module.scss";
import { Check } from 'react-feather';
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import ErrorBanner from '../../ErrorBanner/ErrorBanner';

export default function JobInfoForm(props) {

    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [jobTitle, setJobTitle] = useState(props.user.jobTitle);
    const [companyName, setCompanyName] = useState(props.user.companyName);
    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);

    const firmAdminAuth = props.firmAdminAuth;

    function cancelButton() {
        props.onClose();
    }

    useEffect(() => {
        if (jobTitle || companyName) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    }, [jobTitle, companyName]);

    async function onSubmit() {
        setSubmitDisabled(true)
        setShowErrorBanner(false);
        const columnData = [
            {
                jobTitle
            },
            {
                companyName
            }
        ];
        const myInit = {
            body: {
                columnData
            }
        };

        try {

            await advisorProfileService.update(props.user.professionalId, myInit, firmAdminAuth);

            props.setUser({
                ...props.user,
                jobTitle,
                companyName
            });
            setSubmitDisabled(false)
            props.onClose();
        } catch (error) {
            console.log(error);
            setErrors(error.toString());
            setShowErrorBanner(true);
            setSubmitDisabled(false)
        }
    }


    return (
        <>
            <div className={styles.intoFormContainer}>
                <div className="input-container justify-left mobile-padding">
                    <label htmlFor="jobTitle">Job Title</label>
                    <input
                        className="input full"
                        type="text"
                        placeholder="Job Title"
                        id="jobTitle"
                        value={jobTitle}
                        onChange={e => setJobTitle(e.target.value)}
                    />
                </div>
                <div className="input-container justify-left mobile-padding">
                    <label htmlFor="companyName">Company Name</label>
                    <input
                        className="input full"
                        placeholder="Company Name"
                        type="text"
                        id="companyName"
                        value={companyName}
                        onChange={e => setCompanyName(e.target.value)}
                    />
                </div>
                {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}
            </div>
            <div className="modal-footer">
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>Cancel</Button>
                <Button className="modal-cancel-button" color="gradient-button" onClick={onSubmit} disabled={submitDisabled}><Check size={20} />Confirm</Button>
            </div>
        </>
    )
}

