import React from "react";
import styles from "./Filters.module.scss";
export default function LifeStage(props) {

    const {currentLifeStage, setCurrentLifeStage, lifeStageFilters} = props;
    const stage = lifeStageFilters;

    const selectLifeStage = (id) => {
        if (currentLifeStage === id) {
            return setCurrentLifeStage(0)
        }

        setCurrentLifeStage(id)
    }

    return (
        <>
            <div className={styles.formSection}>
                <h3>Life Stage</h3>
                <div className={styles.verticaloffsetmedium }>Adding a life stage filter may help you narrow down options of financial advisors well-versed in the specific challenges, opportunities, and decisions you're likely facing based on the stage of life you're in.</div>
                    <div className={styles.industryTypesContainer}>
                    {stage.map((item) => {
                        return <div className={currentLifeStage === item.id ?  styles.industryTypeSelected : styles.industryType} key={item.id} onClick={() => selectLifeStage(item.id)}>{item.value}</div>
                    })}
                </div>
            </div>
        </>
    )
}