import React, { useEffect, useState } from 'react';
import styles from './Assessment.module.scss';
import Button from '../../components/Button/Button';
import { ArrowRight, ArrowLeft, Info } from 'react-feather';
import useIsScreenWidth from '../../hooks/useIsScreenWidth';

function AssessmentAssetRange(props) {
    const rangeIndex = [
        { key: 1, value: '$0-$100,000' },
        { key: 2, value: '$100,000-$250,000' },
        { key: 3, value: '$250,000-$500,000' },
        { key: 4, value: '$500,000-$1,000,000' },
        { key: 5, value: '$1,000,000-$3,000,000' },
        { key: 6, value: '$3,000,000-$5,000,000' },
        { key: 7, value: '$5,000,000-$10,000,000' },
        { key: 8, value: '$10,000,000+' },
    ];
    const rangeTextArray = ['$0-$100,000', '$100,000-$250,000', '$250,000-$500,000', '$500,000-$1,000,000', '$1,000,000-$3,000,000', '$3,000,000-$5,000,000', '$5,000,000-$10,000,000', '$10,000,000+'];
    const [currentAccountSize, setCurrentAccountSize] = useState();
    const { setCurrentStage, setAssessmentSelection, preFill, mixpanelTrack } = props;
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (preFill['q5']) {
            setCurrentAccountSize(preFill['q5'].key);
            setDisabled(false);
        }
        mixpanelTrack('Assessment Q5', 'Page View', true);
    }, []);

    useEffect(() => {
        if (currentAccountSize) {
            if (rangeIndex[currentAccountSize - 1].key === 8) {
                setAssessmentSelection('q5', { assetRangeMin: 10000000, assetRangeMax: 100000000 });
                return setDisabled(false);
            }
            const range = rangeIndex[currentAccountSize - 1].value.split('-');
            setAssessmentSelection('q5', {
                assetRangeMin: parseInt(range[0].slice(1).replace(/,/g, '')),
                assetRangeMax: parseInt(range[1].slice(1).replace(/,/g, '')),
                key: rangeIndex[currentAccountSize - 1].key,
            });
            return setDisabled(false);
        }

        return setDisabled(true);
    }, [currentAccountSize]);

    const skipStage = (stage) => {
        setAssessmentSelection('q5', {});
        setCurrentStage(stage);
        mixpanelTrack('Assessment Q5', 'Page Skipped', true);
    };

    const nextStep = () => {
        console.log(currentAccountSize);
        mixpanelTrack('Assessment Q5', 'User Response', currentAccountSize);
        setCurrentStage(6);
    };

    return (
        <>
            <div className={styles.industryContainer}>
                <h1>What is the value of your investable assets?</h1>
                <div className={styles.accountSizeGreyBox}>
                    <div className={styles.accountRangeContainer}>
                        <div className={styles.accountSizeBackgroundBar}>
                            {rangeIndex.map((item) => {
                                if (item.key === 1) {
                                    return (
                                        <div
                                            className={currentAccountSize === item.key ? styles.accountSizeStartSectionSelected : styles.accountSizeStartSection}
                                            key={item.key}
                                            onClick={() => setCurrentAccountSize(1)}
                                        />
                                    );
                                }
                                if (item.key === 8) {
                                    return (
                                        <div
                                            className={currentAccountSize === item.key ? styles.accountSizeEndSectionSelected : styles.accountSizeEndSection}
                                            key={item.key}
                                            onClick={() => setCurrentAccountSize(8)}
                                        />
                                    );
                                }
                                return (
                                    <div
                                        className={currentAccountSize === item.key ? styles.accountSizeSectionSelected : styles.accountSizeSection}
                                        key={item.key}
                                        onClick={() => setCurrentAccountSize(item.key)}
                                    />
                                );
                            })}
                        </div>
                        <div className={styles.assetRangeDashContainer}>
                            <div className={styles.rangeIndicator} />
                            <div className={styles.rangeIndicator} />
                            <div className={styles.rangeIndicator} />
                            <div className={styles.rangeIndicator} />
                            <div className={styles.rangeIndicator} />
                            <div className={styles.rangeIndicator} />
                            <div className={styles.rangeIndicator} />
                            <div className={styles.blankRangeIndicator} />
                        </div>
                        <div className={styles.assetRangeNumericalContainer}>
                            <div className={styles.assetRangeNumber}>100k</div>
                            <div className={styles.assetRangeNumber}>250k</div>
                            <div className={styles.assetRangeNumber}>500k</div>
                            <div className={styles.assetRangeNumber}>1m</div>
                            <div className={styles.assetRangeNumber}>3m</div>
                            <div className={styles.assetRangeNumber}>5m</div>
                            <div className={styles.assetRangeNumber}>10m+</div>
                        </div>
                    </div>
                    <div className={styles.assetRangeTextBox}>
                        <div>{currentAccountSize ? rangeTextArray[currentAccountSize - 1] : 'Please select an asset range'}</div>
                    </div>
                </div>
                <div className={styles.mobileAccountSizeDropdown}>
                    <div className={styles.filtersSelectContainer}>
                        <select className={styles.filtersDropdown} value={currentAccountSize} onChange={(e) => setCurrentAccountSize(parseInt(e.target.value))}>
                            <option className={styles.filtersDropdownValue} value={0}>
                                Please select an asset range
                            </option>
                            {rangeIndex.map((item) => (
                                <option value={item.key} key={item.key}>
                                    {item.value}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {useIsScreenWidth(720) && (
                    <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginTop: '30px', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <Button color='clear-button white' onClick={() => setCurrentStage(4)}>
                            <ArrowLeft size={20} />
                            Back
                        </Button>
                        <span className={styles.skipButton} onClick={() => skipStage(6)}>
                            <b>Skip</b>
                        </span>
                        <Button onClick={() => nextStep()} disabled={disabled}>
                            Next
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                )}
            </div>
            <div className={styles.infoMessageContainer}>
                <Info size={20} color='#ABAAB3' />{' '}
                <span>
                    Please include any kind of investment accounts or retirement accounts you may own. We ask you this so we can best understand your current financial situation, which helps you
                    narrow down your results of advisors who work with clients like you. <b>We understand it's a personal question, which is why your information will never be shared.</b>
                </span>
            </div>
            {!useIsScreenWidth(720) && (
                <div className={styles.mobileBottomNav}>
                    <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <Button color='clear-button white' onClick={() => setCurrentStage(4)}>
                            <ArrowLeft size={20} />
                            Back
                        </Button>
                        <span className={styles.skipButton} onClick={() => skipStage(6)}>
                            <b>Skip</b>
                        </span>
                        <Button onClick={() => nextStep()} disabled={disabled}>
                            Next
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export default AssessmentAssetRange;
