import styles from './QuickFacts.module.scss';
import { Briefcase, Smile, DollarSign, Users } from 'react-feather';

export default function QuickFacts(props) {


    const advisor = props.advisor;
    const {clientNumber, averageAccountSize} = props

    function memberSinceYear(date) {
        const dateFormat = new Date(date);
        return dateFormat.getFullYear();
    }

    return (
        <div className={styles.quickFacts}>
            <div className={styles.headerContainer}>
                <div className={styles.header}>Quick Facts About <span className={styles.userName}>{advisor.firstName}</span></div>
                {props.editMode && <div className={styles.addDetails} onClick={() => props.setShowAdditionalDetailsModal(true)}>Add Details</div>}
            </div>
            <div className={styles.factsContainer}>
                <span className={styles.factContainer}>
                    <Briefcase size={24} color="rgba(170, 133, 248, 1)"></Briefcase>
                    <span className={styles.factText}>Years of Experience:</span>
                    <span className={styles.fact}>{advisor.yearsOfExperience} years</span>
                </span>
                <span className={styles.factContainer}>
                    <Smile size={24} color="rgba(170, 133, 248, 1)"></Smile>
                    <span className={styles.factText}>Member since:</span>
                    <span className={styles.fact}>{memberSinceYear(advisor.memberSince)}</span>
                </span>
                {averageAccountSize && <span className={styles.factContainer}>
                    <DollarSign size={24} color="rgba(170, 133, 248, 1)"></DollarSign>
                    <span className={styles.factText}>Average Account Size:</span>
                    <span className={styles.fact}>${parseInt(averageAccountSize).toLocaleString('en-US')}</span>
                </span>}
                {clientNumber && <span className={styles.factContainer}>
                    <Users size={24} color="rgba(170, 133, 248, 1)"></Users>
                    <span className={styles.factText}>Number of clients:</span>
                    <span className={styles.fact}>{parseInt(clientNumber).toLocaleString('en-US')}</span>
                </span>}
            </div>
        </div>
    )
}