import React from "react";
import styles from "./ScheduleCallNoUserForm.module.scss";
import Button from "../../Button/Button";
import { BookOpen } from 'react-feather';

export default function ScheduleCallComplete(props) {

    const handleRedirect = (destination) => {
        window.location.replace(destination)
    }
    

    return (
        <>
            <div className={styles.scheduleCompleteSection}>
                <img loading="lazy" src="/app/images/meetingRequested.svg" alt="meetingRequest" />
                <h1 className={styles.verticalOffSet}>Your meeting with {props.advisorName} has successfully been requested!</h1>
                <div className={styles.verticalOffSet}>
                    {props.advisorName} will receive your meeting invitation and you will be notified once they approve the meeting. You will be in charge of when you want to meet.
                </div>
            </div>
            <div className={styles.submitFooter}>
                <h3>Check out the AdFi Resources to prepare yourself for the meeting</h3>
                <Button color="clear-button" 
                    onClick={() => handleRedirect('https://www.advisorfinder.com/blog-posts/best-questions-to-ask-your-financial-advisor')}>
                    <BookOpen size={20}/>Resources Here!
                </Button>
            </div>
        </>
    )
}
