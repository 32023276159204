import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../Utils/UserContext';
import styles from './AccountSettings.module.scss';
import ClipLoader from 'react-spinners/ClipLoader';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Settings, ArrowRight, Info, User, Shield } from 'react-feather';
import FormModal from '../../components/Modals/FormModal';
import NameForm from '../../components/Forms/NameForm/NameForm';
import EmailForm from '../../components/Forms/EmailForm/EmailForm';
import JobInfoForm from '../../components/Forms/JobInfoForm/JobInfoForm';
import PhoneNumberForm from '../../components/Forms/PhoneNumberForm/PhoneNumberForm';
import PasswordForm from '../../components/Forms/PasswordForm/PasswordForm';
import DisableAccountForm from '../../components/Forms/DisableAccountForm/DisableAccountForm';
import CalendlyInfo from '../../components/Forms/CalendlyInfo/CalendlyInfo';
import advisorProfileService from '../../services/advisorProfile.service';
import Button from '../../components/Button/Button';
import consumerProfileService from '../../services/consumerProfile.service';
import LocationInfoForm from '../../components/Forms/LocationInfoForm/LocationInfoForm';
import GlobalFooter from '../../components/GlobalFooter/GlobalFooter';
import firmAdminService from '../../services/firmAdmin.service';
import { InlineWidget } from 'react-calendly';

export default function AccountSettings() {
    const navigate = useNavigate();
    const [user, setSettingsUser] = useState(JSON.parse(localStorage.getItem('user')));
    const { setUser } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [activeValue, setActiveValue] = useState('Account Information');
    const [url, setUrl] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [userData, setSetUserData] = useState({});
    const [menuOptions, setMenuOptions] = useState([
        {
            value: 'Account Information',
        },
    ]);

    const [showNameModal, setShowNameModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showJobInfoModal, setShowJobInfoModal] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [showPhoneNumberModal, setShowPhoneNumberModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showCalendlyInfo, setShowCalendlyInfo] = useState(false);
    const [showAccountDisabledModal, setShowAccountDisabledModal] = useState(false);
    const [isAdvisor, setIsAdvisor] = useState(false);
    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const [showCalendlyPreview, setShowCalendlyPreview] = useState(false);

    const [isDesktop, setDesktop] = useState(window.innerWidth > 1040);

    const modalText =
        "To give you the best user experience, we want to integrate meetings with your existing calendar seamlessly. By giving us your Calendly URL, future clients of yours can easily schedule a time to meet with you directly from your AdvisorFinder profile. This saves you time by avoiding back-and-forth communication to schedule a meeting. You'll be able to set it up later on!";

    useEffect(async () => {
        if (!user) {
            navigate(`/404`, { replace: true });
        } else if (user.groups.includes('Advisor')) {
            setIsAdvisor(true);
            if (!menuOptions.some((e) => e.value === 'Calendar Setup')) {
                setMenuOptions([...menuOptions, { value: 'Calendar Setup' }]);
            }
            await getUserInfo('advisor');
            setUrl(userData.calendlyUrl);
            setLoading(false);
        } else if (user.groups.includes('Consumer')) {
            setIsAdvisor(false);
            await getUserInfo('consumer');
        } else if (user.groups.includes('FirmAdmin')) {
            setIsAdvisor(false);
            await getUserInfo('firmAdmin');
        }
        setLoading(false);
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    useEffect(() => {
        setUrl(userData.calendlyUrl);
        setJobTitle(userData.jobTitle);
        setCompanyName(userData.companyName);
    }, [userData]);

    useEffect(() => {
        setUser(user);
        getUserInfo(user.groups.includes('Advisor') ? 'advisor' : user.groups.includes('FirmAdmin') ? 'firmAdmin' : 'consumer');
    }, [user]);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1040);
    };

    async function getUserInfo(type) {
        try {
            let userResponse = {};
            if (type === 'advisor') {
                userResponse = await advisorProfileService.getOne(user.id);
            } else if (type === 'consumer') {
                userResponse = await consumerProfileService.getOne(user.email);
            } else if (type === 'firmAdmin') {
                userResponse = await firmAdminService.getOne(user.id);
            }
            setSetUserData(userResponse);
        } catch (error) {
            console.log(error);
            navigate(`/404`, { replace: true });
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setLoading(true);
        setDisabled(true);

        const columnData = [
            {
                calendlyUrl: url,
            },
            {
                calendlyUser: url,
            },
        ];

        const myInit = {
            body: {
                columnData,
            },
        };
        try {
            await advisorProfileService.update(user.id, myInit);
        } catch (error) {
            console.log(error);
        } finally {
            setDisabled(false);
            setLoading(false);
        }
    }

    return (
        <>
            {loading && (
                <div className='spinnerContainer'>
                    <ClipLoader color={'var(--adfi_blue500)'} loading={loading} size={100} />
                </div>
            )}
            {showNameModal && (
                <FormModal onClose={() => setShowNameModal(false)} show={showNameModal} title={'Edit Name'} setUser={setSettingsUser}>
                    <NameForm user={user} isAdvisor={isAdvisor}></NameForm>
                </FormModal>
            )}
            {showEmailModal && (
                <FormModal onClose={() => setShowEmailModal(false)} show={showEmailModal} title={'Edit Email'} setUser={setSettingsUser}>
                    <EmailForm user={user}></EmailForm>
                </FormModal>
            )}
            {showJobInfoModal && (
                <FormModal onClose={() => setShowJobInfoModal(false)} show={showJobInfoModal} title={'Edit Job Info'} setUser={setSetUserData}>
                    <JobInfoForm user={userData}></JobInfoForm>
                </FormModal>
            )}
            {showPhoneNumberModal && (
                <FormModal onClose={() => setShowPhoneNumberModal(false)} show={showPhoneNumberModal} title={'Edit Phone Number'} setUser={setSettingsUser}>
                    <PhoneNumberForm user={user}></PhoneNumberForm>
                </FormModal>
            )}
            {showLocationModal && (
                <FormModal onClose={() => setShowLocationModal(false)} show={showLocationModal} title={'Edit Location Information'} setUser={setSetUserData}>
                    <LocationInfoForm user={userData}></LocationInfoForm>
                </FormModal>
            )}
            {showPasswordModal && (
                <FormModal onClose={() => setShowPasswordModal(false)} show={showPasswordModal} title={'Change Password'} setUser={setSettingsUser}>
                    <PasswordForm user={user} setShowPasswordChange={setShowPasswordChange}></PasswordForm>
                </FormModal>
            )}
            {showCalendlyInfo && (
                <FormModal onClose={() => setShowCalendlyInfo(false)} show={showCalendlyInfo} title={'Speed up your meeting requests'}>
                    <CalendlyInfo text={modalText}></CalendlyInfo>
                </FormModal>
            )}
            {showAccountDisabledModal && (
                <FormModal onClose={() => setShowAccountDisabledModal(false)} show={showAccountDisabledModal} title={'Disable your account'}>
                    <DisableAccountForm user={user} enable={false}></DisableAccountForm>
                </FormModal>
            )}
            {showCalendlyPreview && (
                <FormModal onClose={() => setShowCalendlyPreview(false)} show={showCalendlyPreview} noPadding={true}>
                    <div>
                        <InlineWidget url={url} />
                    </div>
                </FormModal>
            )}

            <div className={`${styles.pageContainer} ${styles.sideNavMargin}`}>
                <div className={styles.settingsHeader}>
                    <Settings size={24} color={'var(--adfi_blue500)'} />
                    <div className={styles.settingsHeaderText}>Settings</div>
                    {!user.groups.includes('FirmAdmin') && (
                        <div className={styles.rightSideButtons}>
                            <Link to={isAdvisor ? `/advisor-profile/${user.id}/${userData.firstName}-${userData.lastName}/` : '/consumer-profile'}>
                                <Button color='gradient-button'>
                                    <User size={20} />
                                    {isDesktop && <>My Profile</>}
                                </Button>
                            </Link>
                        </div>
                    )}
                </div>
                {showPasswordChange && (
                    <div className={styles.editDisclosure}>
                        <Shield />
                        Password has been changed successfully!
                    </div>
                )}

                <div className={styles.settingContainer}>
                    <ul className={styles.leftMenuContainer}>
                        {menuOptions.map((item, index) => (
                            <li key={index} className={`${styles.menuOption} ${item.value === activeValue ? styles.active : ''}`} onClick={() => setActiveValue(item.value)}>
                                {item.value}
                            </li>
                        ))}
                    </ul>

                    <div className={styles.infoContainer}>
                        <div className={styles.infoHeader}>
                            <h2> {activeValue}</h2>
                        </div>

                        {activeValue === 'Account Information' && (
                            <div className={styles.accountInfoContainer}>
                                <div className={styles.accountInfoRow}>
                                    Name:{' '}
                                    <span className={styles.accountInfoValue} onClick={() => setShowNameModal(true)}>
                                        {userData.firstName} {userData.lastName}
                                        <ArrowRight size={20} color='var(--adfi_dark400)'></ArrowRight>
                                    </span>
                                </div>
                                <div className={styles.accountInfoRow}>
                                    Email:{' '}
                                    <span className={styles.accountInfoValue} onClick={() => setShowEmailModal(true)}>
                                        {userData.email}
                                        <ArrowRight size={20} color='var(--adfi_dark400)'></ArrowRight>
                                    </span>
                                </div>
                                {user.groups.includes('Advisor') && (
                                    <div className={styles.accountInfoRow}>
                                        Job Info:{' '}
                                        <span className={styles.accountInfoValue} onClick={() => setShowJobInfoModal(true)}>
                                            {jobTitle} at {companyName}
                                            <ArrowRight size={20} color='var(--adfi_dark400)'></ArrowRight>
                                        </span>
                                    </div>
                                )}
                                {(user.groups.includes('Advisor') || user.groups.includes('FirmAdmin')) && (
                                    <div className={styles.accountInfoRow}>
                                        Phone Number:{' '}
                                        <span className={styles.accountInfoValue} onClick={() => setShowPhoneNumberModal(true)}>
                                            {userData.phoneNumber}
                                            <ArrowRight size={20} color='var(--adfi_dark400)'></ArrowRight>
                                        </span>
                                    </div>
                                )}
                                {user.groups.includes('Advisor') && (
                                    <div className={styles.accountInfoRow}>
                                        Location:{' '}
                                        <span className={styles.accountInfoValue} onClick={() => setShowLocationModal(true)}>
                                            {userData.city}, {userData.state} {userData.zipCode}
                                            <ArrowRight size={20} color='var(--adfi_dark400)'></ArrowRight>
                                        </span>
                                    </div>
                                )}
                                <div className={styles.accountInfoRow}>
                                    Change Password:{' '}
                                    <span className={styles.accountInfoValue} onClick={() => setShowPasswordModal(true)}>
                                        <ArrowRight size={20} color='var(--adfi_dark400)'></ArrowRight>
                                    </span>
                                </div>
                                <div className={styles.accountInfoRow}>
                                    Disable Account:{' '}
                                    <span className={styles.accountInfoValue} onClick={() => setShowAccountDisabledModal(true)}>
                                        <ArrowRight size={20} color='var(--adfi_dark400)'></ArrowRight>
                                    </span>
                                </div>
                            </div>
                        )}

                        {activeValue === 'Calendar Setup' && (
                            <>
                                <h1 className={styles.calendlyHeader}>Please paste your Calendly URL</h1>
                                <p className={styles.calendlyText}>
                                    Why do we need your Calendly URL?
                                    <span
                                        className={styles.purpleText}
                                        onClick={() => {
                                            setShowCalendlyInfo(true);
                                        }}>
                                        {' '}
                                        Click here to find out why. <Info size={16} />
                                    </span>
                                </p>
                                <form className={styles.calendlyForm} onSubmit={(e) => handleSubmit(e)}>
                                    <div>Step 1: Go to Calendly</div>
                                    <div>Step 2: Click the “Copy Link” button in Calendly</div>
                                    <div className='input-container justify-left mobile-padding' style={{ width: '75%' }}>
                                        <label htmlFor='url'>Calendly URL</label>
                                        <input className='input full' id='url' placeholder='Ex: https://calendly.com/{username}/{meetingName}' value={url} onChange={(e) => setUrl(e.target.value)} />

                                        {url && (
                                            <div className={styles.preivewButton}>
                                                <Button color='clear-button' disabled={!url} onClick={() => setShowCalendlyPreview(true)}>
                                                    See Preview
                                                    {/* <ArrowRight size={20} /> */}
                                                </Button>
                                            </div>
                                        )}
                                    </div>

                                    <div className={styles.buttonContainer}>
                                        <Button color='gradient-button' type='submit' disabled={disabled}>
                                            Looks good!
                                            <ArrowRight size={20} />
                                        </Button>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <GlobalFooter background={true} />
        </>
    );
}
