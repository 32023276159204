import React, { useEffect, useState } from 'react';
import styles from './Assessment.module.scss';
import Button from '../../components/Button/Button';
import { ArrowRight, ArrowLeft, Info } from 'react-feather';
import useIsScreenWidth from '../../hooks/useIsScreenWidth';

function AssessmentOther(props) {
    const { setCurrentStage, setAssessmentSelection, mixpanelTrack } = props;
    const [disabled, setDisabled] = useState(true);
    const [otherOptions, setOtherOptions] = useState([
        { name: 'I am a business owner', id: 'businessOwner', value: false },
        { name: 'I am a single parent', id: 'singleParent', value: false },
        { name: 'I have or will receive an inheritance', id: 'inheritance', value: false },
        { name: 'I have outstanding student loans', id: 'studentLoans', value: false },
        { name: 'I have a disability or a child with a disability', id: 'disability', value: false },
    ]);

    useEffect(() => {
        mixpanelTrack('Assessment Q6', 'Page View', true);
    }, []);

    const handleToggle = (i) => {
        let tempState = [...otherOptions];
        if (tempState[i].value === true) {
            tempState[i].value = false;
        } else {
            tempState[i].value = true;
        }
        setDisabled(false);
        setOtherOptions(tempState);
    };

    const skipStage = (stage) => {
        setAssessmentSelection('q6', {});
        setCurrentStage(stage);
        mixpanelTrack('Assessment Q6', 'Page Skipped', true);
    };

    const collectOptions = () => {
        setAssessmentSelection('q6', {
            q1: otherOptions[0].value,
            q2: otherOptions[1].value,
            q3: otherOptions[2].value,
            q4: otherOptions[3].value,
            q5: otherOptions[4].value,
        });
        return nextStep();
    };

    const nextStep = () => {
        console.log(otherOptions);
        const infoArray = otherOptions
            .filter((option) => option.value === true)
            .map((selection) => {
                return {
                    id: selection.id,
                    value: selection.name,
                };
            });
        mixpanelTrack('Assessment Q6', 'User Response', infoArray);
        setCurrentStage(7);
    };

    return (
        <>
            <div className={styles.industryContainer}>
                <h1 style={{ width: '95%' }}>Do any of these additional situations apply to you?</h1>
                <h2>(Select all that apply)</h2>
                <div className={styles.contactTypeContainer}>
                    {otherOptions.map((type, i) => {
                        return (
                            <div key={i} className={styles.contactType} onClick={() => handleToggle(i)}>
                                <input type='checkbox' className={styles.inputHorizontalOffset} value={type.name} checked={type.value} onChange={() => {}} />
                                {type.name}
                            </div>
                        );
                    })}
                </div>
                {useIsScreenWidth(720) && (
                    <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginTop: '30px', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <Button color='clear-button white' onClick={() => setCurrentStage(5)}>
                            <ArrowLeft size={20} />
                            Back
                        </Button>
                        <span className={styles.skipButton} onClick={() => skipStage(7)}>
                            <b>Not sure right now</b>
                        </span>
                        <Button onClick={() => collectOptions()} disabled={disabled}>
                            Next
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                )}
            </div>
            <div className={styles.infoMessageContainer}>
                <Info size={20} color='#ABAAB3' /> <span>This will help you narrow down your choices of financial advisors based on what you value most.</span>
            </div>
            {!useIsScreenWidth(720) && (
                <div className={styles.mobileBottomNav}>
                    <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <Button color='clear-button white' onClick={() => setCurrentStage(5)}>
                            <ArrowLeft size={20} />
                            Back
                        </Button>
                        <span className={styles.skipButton} onClick={() => skipStage(7)}>
                            <b>Skip</b>
                        </span>
                        <Button onClick={() => nextStep()} disabled={disabled}>
                            Next
                            <ArrowRight size={20} />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export default AssessmentOther;
