import validator from 'validator';

function validateFirmAdminApply(values) {
    let errors = {};

    if (!validator.matches(values.firstName, "^[a-zA-Z-,.' -]*$")) {
        errors.name = 'Enter valid first and last name'
    }

    if (!validator.matches(values.lastName, "^[a-zA-Z-,.' -]*$")) {
        errors.name = 'Enter valid first and last name'
    }

    if (!validator.isEmail(values.email)) {
        errors.email = 'Enter valid email'
    }

    if (!validator.isMobilePhone(values.phoneNumber)) {
        errors.phoneNumber = 'Enter valid phone number'
    }

    return errors;
}

export default validateFirmAdminApply;