import React, { useState, useEffect, useContext } from 'react';
import { Grid } from 'react-feather';
import styles from './FirmAdminDashboard.module.scss';
import ClipLoader from 'react-spinners/ClipLoader';
import ErrorBanner from '../../components/ErrorBanner/ErrorBanner';
import GlobalFooter from '../../components/GlobalFooter/GlobalFooter';
// import useMixPanelEvent from '../../Utils/MixPanel';
import MarketplaceAdvisorCard from '../Marketplace/MarketplaceAdvisorCard/MarketplaceAdvisorCard';
import { UserContext } from '../../Utils/UserContext';
import { useNavigate } from 'react-router';
import { Search, XCircle } from 'react-feather';
import firmAdminService from '../../services/firmAdmin.service';

const classnames = require('classnames');

function FirmAdminDashboard() {
    // const mixpanelTrack = useMixPanelEvent();
    const navigate = useNavigate();

    const { user } = useContext(UserContext);

    const [loading, setLoading] = useState(false);
    const [advisorData, setAdvisorData] = useState([]);
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [error, setError] = useState('');
    const [isDesktop] = useState(window.innerWidth > 1040);
    const [textSearch, setTextSearch] = useState('');
    const [searchResults, setSearchResults] = useState(advisorData);

    const [activeChecked, setActiveChecked] = useState(false);
    const [deactiveChecked, setDeactiveChecked] = useState(false);

    const handleChange = (event) => {
        setTextSearch(event.target.value);
        setActiveChecked(false)
        setDeactiveChecked(false)
        const searchText = event.target.value;
        let searchFilter = advisorData.filter((advisor) => advisor.firstName.toLowerCase().includes(searchText.toLowerCase()) || advisor.lastName.toLowerCase().includes(searchText.toLowerCase()));
        setSearchResults(searchFilter);
    };

    function handleClearText() {
        setTextSearch('');
        setSearchResults(advisorData);
    }

    const updateMedia = () => {};

    useEffect(async () => {
        setShowErrorBanner(false);
        try {
            setLoading(true);
            //get firm advisors
            if (!user.groups.includes('FirmAdmin')) {
                navigate(`/404`, { replace: true });
            } else {
                let advisors = await firmAdminService.getFirmAdvisors(user.firmID);
                setAdvisorData(advisors.advisors);
                setSearchResults(advisors.advisors);
            }

            setLoading(false);
        } catch (e) {
            setError(e);
            setShowErrorBanner(true);
        }
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    useEffect(() => {
        let tempSearchResults = advisorData;
        if (activeChecked && deactiveChecked) {
            setSearchResults(tempSearchResults);
        } else {
            if (activeChecked) {
                tempSearchResults = tempSearchResults.filter((advisor) => advisor.accountEnabled && advisor.hasLoggedIn);
            }
            if (deactiveChecked) {
                tempSearchResults = tempSearchResults.filter((advisor) => !advisor.accountEnabled || !advisor.hasLoggedIn);
            }
        }
        setSearchResults(tempSearchResults);
    }, [activeChecked, deactiveChecked]);

    const handleCheck = (event, type) => {
        if (type === 'active') {
            console.log(event.target.checked);
            setActiveChecked(event.target.checked);
        } else if (type === 'deactive') {
            setDeactiveChecked(event.target.checked);
        }
    };

    return (
        <>
            {loading && (
                <div className='spinnerContainer'>
                    <ClipLoader color={'var(--adfi_blue500)'} loading={loading} size={100} />
                </div>
            )}

            <>
                <div className={classnames(styles.pageContainer)}>
                    <div className={styles.contentContainer}>
                        <div className={styles.dashboardContainer}>
                            <div className={styles.dashboard}>
                                <Grid size={20} color='var(--adfi_blue400)' />
                                <h1>Firm Advisors</h1>
                            </div>
                            <label className={styles.optionRow}>
                                <input value='Active Advisors' checked={activeChecked} onChange={(e) => handleCheck(e, 'active')} type='checkbox' />
                                <span className={styles.checkmark}></span>
                                <span>Active Advisors</span>
                            </label>
                            <label className={styles.optionRow}>
                                <input value='Inactive Advisors' checked={deactiveChecked} onChange={(e) => handleCheck(e, 'deactive')} type='checkbox' />
                                <span className={styles.checkmark}></span>
                                <span>Inactive Advisors</span>
                            </label>
                            <div className={styles.searchBarWrapper}>
                                <Search className={styles.searchIcon} size={16} color='rgba(45, 43, 65, 1)' />
                                <input
                                    className={styles.searchBar}
                                    autoComplete='new-password'
                                    placeholder={`Search Advisors`}
                                    type='text'
                                    id='search'
                                    value={textSearch}
                                    onChange={(e) => handleChange(e)}
                                />
                                <XCircle className={styles.cancelIcon} size={16} color='rgba(171, 170, 179, 1)' onClick={handleClearText} />
                            </div>
                        </div>
                        <div className={styles.advisorsContainer}>
                            {advisorData && (
                                <div className={`${styles.advisorCardsContainer} ${!isDesktop ? styles.blockScrolling : ''}`}>
                                    {searchResults.map((singleAdvisor, index) => (
                                        <MarketplaceAdvisorCard key={index} data={singleAdvisor} user={user} />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{error}</ErrorBanner>}
                </div>
                <GlobalFooter background={true} />
            </>
        </>
    );
}

export default FirmAdminDashboard;
