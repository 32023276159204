import React, { useState, useEffect } from "react";
import styles from "./JobHistory.module.scss";
import { ChevronUp, Plus, Edit2 } from 'react-feather';

const classnames = require('classnames');

export default function JobHistory(props) {

    const editMode = props.editMode;
    const data = props.data;

    const [showMore, setShowMore] = useState(true);

    useEffect(() => {
        if (editMode) setShowMore(true)
    }, [editMode])


    return (
        <>

            <div className={styles.servicesContainer_edit}>
                <div className={styles.servicesHeader_edit}><ChevronUp className={classnames(styles.showMoreArrow, showMore && styles.open)} onClick={() => setShowMore(!showMore)} size={20} color="rgba(45, 43, 65, 1)" /><h2>Job History</h2><div className={styles.countNumber}>{data.length}</div>
                    {editMode && <div className={styles.addIcons} onClick={props.onClick}><Plus size={16} color="rgba(129, 128, 141, 1)" /></div>}
                </div>
                <div className={classnames(styles.dataContainer_edit, !showMore && styles.clamp)}>
                    {data.map((job, index) => (
                        <div className={`${styles.educationContainer}`} key={index}>
                            {editMode && <Edit2 size={16} className={styles.editPencil} onClick={() => { props.onEdit(job) }}></Edit2>}
                            <div className={styles.serviceText_edit}>
                                <span className={styles.serviceName}>{job.company}</span>
                                <span>{job.position}</span>
                                <span>{job.startYear}-{job.endYear}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </>
    )
}