import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../Utils/UserContext';

export function RequireAuth({ children }) {
    const { user, setUser } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(null);
    useEffect(() => {
        getCurrentUser()
            .then((response) => {
                console.log(response);
                setIsAuth(true);
            })
            .catch((error) => {
                if (user && user.groups.includes('Consumer') && user.id) {
                    setIsAuth(true);
                } else {
                    const encodedURI = encodeURIComponent(`${location.pathname}${location.search}`);
                    navigate(`/signin?redirectUri=${encodedURI}`);
                }
            });
    }, []);

    return isAuth && children;
}
