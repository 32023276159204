import React, { useState, useEffect } from "react";
import styles from "./AdditionalDetailsForm.module.scss";
import { Check } from 'react-feather';
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import validateAdditionalDetails from '../../../Utils/validateAdditionalDetails'
import ErrorBanner from "../../ErrorBanner/ErrorBanner";

export default function AdditionalDetailsForm(props) {
    const {clientNumber, setClientNumber, averageAccountSize, setAverageAccountSize, firmAdminAuth} = props;
    const [AUM, setAUM] = useState(props.advisor.aum ?? '');
    const [clientNumberTemp, setClientNumberTemp] = useState(clientNumber ?? '');
    const [averageAccountSizeTemp, setAverageAccountSizeTemp] = useState(averageAccountSize ?? '');
    const [minAccountSize, setMinAccountSize] = useState(props.advisor.minAccountSize ?? '');
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);


    useEffect(() => {
        if(AUM || clientNumberTemp || averageAccountSizeTemp || minAccountSize) {
            setSubmitDisabled(false)
        } else {
            setSubmitDisabled(true)
        }
    }, [AUM, averageAccountSizeTemp, clientNumberTemp, minAccountSize]);

    function validateFields(e) {
        e.preventDefault()
        setErrors({})
        const validate = validateAdditionalDetails({ AUM, clientNumberTemp, averageAccountSizeTemp });

        if (Object.keys(validate).length !== 0) {
            setErrors(validate)
            return;
        } else {
            onSubmit()
        }
    }

    function cancelButton() {
        props.onClose();
    }

    async function onSubmit() {

        setSubmitDisabled(true);
        setShowErrorBanner(false);

        const advisorColumnData = [
            {
                aum: AUM
            },
            {
                clientNumber: clientNumberTemp
            },
            {
                averageAccountSize: averageAccountSizeTemp
            },
            {
                minAccountSize: minAccountSize
            }
        ];
        const myInit = {
            body: {
                columnData: advisorColumnData
            }
        };

        try {
            await advisorProfileService.update(props.advisor.professionalId, myInit, firmAdminAuth);
            setSubmitDisabled(false)
            setClientNumber(clientNumberTemp)
            setAverageAccountSize(averageAccountSizeTemp)
            props.onClose();
        } catch (error) {
            setShowErrorBanner(true);
            setSubmitDisabled(false)
            console.log(error);
        }
    }




    return (
        <>
            <div className={styles.additionalDetailsContainer}>
                <div className={styles.formSection}>
                    <label htmlFor="AUM">What is your AUM? (Individual, not firm AUM!) (Optional)</label>
                    <input
                        className="input full"
                        type="number"
                        placeholder="Please enter your AUM on an individual basis"
                        id="AUM"
                        value={AUM}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        onChange={e => {setAUM(e.target.value); props.setAdvisor({...props.advisor, aum: e.target.value})}}
                    />
                    {errors.AUM && <p className='error-message'>{errors.AUM}</p>}
                </div>

                <div className={styles.formSection}>
                    <label htmlFor="clientNumber">How many clients do you have? (Optional)</label>
                    <input
                        className="input full"
                        type="number"
                        placeholder="Please enter how many clients you have"
                        id="clientNumber"
                        value={clientNumberTemp}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        onChange={e => setClientNumberTemp(e.target.value)}
                    />
                    {errors.clientNumber && <p className='error-message'>{errors.clientNumber}</p>}
                </div>

                <div className={styles.formSection}>
                    <label htmlFor="averageAccountSize">What is your average account size? (Optional)</label>
                    <input
                        className="input full"
                        type="number"
                        placeholder="Please enter your average account size"
                        id="averageAccountSize"
                        value={averageAccountSizeTemp}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        onChange={e => setAverageAccountSizeTemp(e.target.value)}
                    />
                </div>

                <div className={styles.formSection}>
                    <label htmlFor="minAccountSize">Do you have an account minimum? (Optional)</label>
                    <input
                        className="input full"
                        type="number"
                        placeholder="Please enter your minimum account size"
                        id="minimumAccountSize"
                        value={minAccountSize}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        onChange={e => {setMinAccountSize(e.target.value); props.setAdvisor({...props.advisor, minAccountSize: e.target.value})}}
                    />
                </div>

            </div>

            <div className={styles.errorSection}>
            {showErrorBanner && <ErrorBanner flex={true} onClick={() => setShowErrorBanner(false)}>Error submiting details, please try again later</ErrorBanner>}
            </div>
            
            <div className={styles.footerContainer}>
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>Cancel</Button>
                <Button className="modal-cancel-button" color="gradient-button" onClick={(e) => validateFields(e)} disabled={submitDisabled}><Check size={20} />Confirm</Button>
            </div>
            
        </>
    )
}