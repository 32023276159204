import styles from "./Disclosure.module.scss";
import { advisorFinderDisclosure } from '../../constants/disclosure';

export default function AdvisorFinderDisclosure() {
    return (
        <div className={styles.disclosureContainer}>
            <span className={styles.disclosureHeader}>AdvisorFinder Disclosure</span>
            <div className={styles.disclosureText}>{advisorFinderDisclosure}</div>
        </div>
    )
}