import React, { useState, useEffect } from "react";
import styles from "./ProfPicForm.module.scss";
import './ProfPicForm.module.scss';
import { Upload, Check } from 'react-feather';
import Button from "../../Button/Button";
import ErrorBanner from '../../ErrorBanner/ErrorBanner';
import 'react-image-crop/src/ReactCrop.scss'
import ImageCropper from "../../../Utils/ImageCropper";


const classnames = require('classnames');


export default function ProfPicForm(props) {

    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [profilePicture, setProfilePicture] = useState(props.currentProfilePicture);
    const [newProfilePicture, setNewProfilePicture] = useState(null);
    const [croppedImage, setCroppedImage] = useState(undefined);
    const [croppedBlob, setCroppedBlob] = useState(undefined)

    const [tempfile, setTempFile] = useState({});
    const [errors, setErrors] = useState('');
    const [showErrorBanner, setShowErrorBanner] = useState(false);

    useEffect(() => {
        setProfilePicture(props.currentProfilePicture);
    }, [props.currentProfilePicture]);

    let fileInput = React.createRef();


    function cancelButton() {
        setProfilePicture(props.currentProfilePicture);
        if (props.currentProfilePicture === '') {
            setSubmitDisabled(true);
        }
        props.onClose();
    }

    async function onSubmit() {
        if (newProfilePicture && croppedBlob) {
            props.setProfilePictureFile(croppedBlob);
        } else {
            props.setProfilePictureFile(tempfile);
        }
        props.onClose();
    }

    function onOpenFileDialog() {
        fileInput.current.click();
    }

    async function onProcessFile(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file.size > 5e+6) {
            setErrors('File Size Must Be Less Than 5MB');
            setShowErrorBanner(true)
        } else {
            setShowErrorBanner(false)
            try {
                reader.readAsDataURL(file);
            } catch (err) {
                console.log(err);
            }
            reader.onloadend = () => {
                setNewProfilePicture(reader.result);
                setProfilePicture(reader.result);
                setSubmitDisabled(false);
                setTempFile(file);
                e.target.value = null;
            };
        }
    };

    function handleCrop(croppedImage, croppedBlob) {
        setCroppedImage(croppedImage)
        setCroppedBlob(croppedBlob)
    };

    return (
        <>
            <div className={styles.profPicFormContainer}>
                <div className={styles.picturePreviewContainer}>
                    <a href="#">
                        <input
                            type="file"
                            onChange={onProcessFile}
                            ref={fileInput}
                            hidden={true}
                            accept="image/*"
                        />
                    </a>
                    {!newProfilePicture && <><img loading="lazy" className={classnames(styles.picturePreviewImg, !(profilePicture === '') ? styles.gradientBorder : styles.pupleBorder)} src={!(profilePicture === '') ? profilePicture : "/app/images/profilePictureDefault.svg"} alt="profile_picture"></img>
                        {(profilePicture === '') && <div className={styles.uploadImgText} onClick={() => onOpenFileDialog()} ><Upload size={16} color="var(--adfi_blue500)" /><span>Upload photo</span></div>}
                    </>}
                    {newProfilePicture &&
                        <div className={styles.imgCropper}>
                            <ImageCropper
                                imageToCrop={newProfilePicture}
                                onImageCropped={(croppedImage, croppedBlob) => handleCrop(croppedImage, croppedBlob)}
                            />
                            {croppedImage && <img loading="lazy" className={classnames(styles.picturePreviewImg, !(croppedImage === '') ? styles.gradientBorder : styles.pupleBorder)} src={croppedImage} alt="profile_picture"></img>}
                        </div>
                    }
                </div>
                {!newProfilePicture &&
                    <div className={styles.pictureSmallPreviewContainer}>
                        <span className={styles.pictuePreviewHeader}>Show clients the best version of yourself!</span>
                        <div className={styles.smallPicturesContainer}>
                            <img loading="lazy" className={classnames(styles.smallPicture1, !(profilePicture === '') ? styles.gradientBorder : styles.pupleBorder)} src={!(profilePicture === '') ? profilePicture : "/app/images/profilePictureDefault.svg"} alt="profile_picture_small1"></img>
                            <img loading="lazy" className={classnames(styles.smallPicture2, !(profilePicture === '') ? styles.gradientBorder : styles.pupleBorder)} src={!(profilePicture === '') ? profilePicture : "/app/images/profilePictureDefault.svg"} alt="profile_picture_small2"></img>
                            <img loading="lazy" className={classnames(styles.smallPicture3, !(profilePicture === '') ? styles.gradientBorder : styles.pupleBorder)} src={!(profilePicture === '') ? profilePicture : "/app/images/profilePictureDefault.svg"} alt="profile_picture_small3"></img>
                        </div>
                        <span>Must be an actual photo of you. <br />
                            Logos, clip-art, group photos, and digitally-altered images are not allowed. Square images work best.</span>
                    </div>}

                {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}
            </div>

            <div className="modal-footer">
                {!(profilePicture === '') && <> <Button className="modal-cancel-button" color="clear-button" onClick={() => onOpenFileDialog()}>Change image</Button>
                    <div className={styles.modalButtons}>
                        <Button className="modal-cancel-button" color="gradient-button" onClick={onSubmit} disabled={submitDisabled}><Check size={20} />Confirm</Button>
                    </div> </>}
                {(profilePicture === '') && <>
                    <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>Cancel</Button>
                    <Button className="modal-cancel-button" color="gradient-button" onClick={onSubmit} disabled={submitDisabled}><Check size={20} />Confirm</Button>
                </>}
            </div>

        </>
    )
}

