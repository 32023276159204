import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({ title, description, name, type, canonical }) {
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <link rel="canonical" href={canonical} hreflang="en-us"></link>
            <title>{title}</title>
            <meta name='description' content={description} />
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            { /* End Facebook tags */}
            { /* Twitter tags */}
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={'summary'} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content="https://uploads-ssl.webflow.com/627ea2baa1713902cd0ee593/64c7d9897b7fc5d03e1298d5_advisorfinder-opengraph-2023.png"></meta>
            { /* End Twitter tags */}
        </Helmet>
    )
}