import React, { useState, useEffect } from "react";
import styles from "./PasswordForm.module.scss";
import { Check } from 'react-feather';
import Button from "../../Button/Button";
import { updatePassword } from 'aws-amplify/auth';
import ErrorBanner from '../../../components/ErrorBanner/ErrorBanner';
import validateConfirmPassword from "../../../Utils/validateConfirmPassword";

export default function PasswordForm(props) {

    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);


    function cancelButton() {
        props.onClose();
    }

    useEffect(() => {
        if (oldPassword && newPassword && confirmPassword) {
            const password = newPassword
            const validate = validateConfirmPassword({ oldPassword, password, confirmPassword });
            if (Object.keys(validate).length === 0) {
                setSubmitDisabled(false);
                setErrors(validate)
            } else {
                setErrors(validate)
            }
        } else {
            setSubmitDisabled(true);
        }
    }, [newPassword, confirmPassword]);

    async function onSubmit() {
        setSubmitDisabled(true)
        setShowErrorBanner(false);

        try {
            await updatePassword({oldPassword, newPassword});
            setNewPassword('');
            setOldPassword('');
            setConfirmPassword('');
            setSubmitDisabled(false)
            props.setShowPasswordChange(true);
            props.onClose();
        } catch (error) {
            console.log(error);
            setErrors(error.toString());
            setShowErrorBanner(true);
            setSubmitDisabled(false)
        }
    }




    return (
        <>
            <div className={styles.intoFormContainer}>
                <div className="input-container justify-left mobile-padding">
                    <label htmlFor="oldPassword">Old Password</label>
                    <input
                        className="input full"
                        placeholder="Old Password"
                        id="oldPassword"
                        type="password"
                        value={oldPassword}
                        onChange={e => setOldPassword(e.target.value)}
                    />
                </div>
                <div className="input-container justify-left mobile-padding">
                    <label htmlFor="newPassword">New Password</label>
                    <input
                        className="input full"
                        placeholder="New Password"
                        id="newPassword"
                        type="password"
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                    />
                </div>
                <div style={{ color: 'grey', opacity: '90%', textAlign: 'left' }}>Must consist of 8+ characters, upper and lowercase characters, 1 digit and 1 special symbol</div>
                <div className="input-container justify-left mobile-padding">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                        className="input full"
                        placeholder="Confirm Password"
                        id="confirmPassword"
                        type="password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                </div>
                {errors.password && <p className='error-message'>{errors.password}</p>}
                {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}
            </div>
            <div className="modal-footer">
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>Cancel</Button>
                <Button className="modal-cancel-button" color="gradient-button" onClick={onSubmit} disabled={submitDisabled}><Check size={20} />Confirm</Button>
            </div>
        </>
    )
}

