import React from "react";
import styles from "./VerifiedByAdfi.module.scss";

export default function VerifiedByAdfi() {
    return (
        <>
            <div className={styles.formContainer}>
                    <div className={styles.verifiedModelContainer}>
                        <div className={styles.verifiedTextContainer}>
                            <div className={styles.verifiedTextValue}>Verified Identity</div>
                            <div className={styles.verifiedTextDescription}>At AdvisorFinder, we know that authenticity is key to finding a financial advisor. The "Verified Identity" badge on this profile indicates that the advisor has verified thier identity.</div>
                        </div>
                    </div>
            </div>
        </>
    )
}