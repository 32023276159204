import React, { useState, useEffect } from "react";
import styles from "./LocationInfoForm.module.scss";
import { Check } from 'react-feather';
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import ErrorBanner from '../../ErrorBanner/ErrorBanner';
import { states } from "../../../constants/states";

export default function LocationInfoForm(props) {

    const [submitDisabled, setSubmitDisabled] = useState(true);

    const [city, setCity] = useState(props.user.city);
    const [state, setState] = useState(props.user.state);
    const [zipCode, setZipCode] = useState(props.user.zipCode);

    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);

    const firmAdminAuth = props.firmAdminAuth;

    function cancelButton() {
        props.onClose();
    }

    useEffect(() => {
        if (!city || !state || !zipCode) {
            setSubmitDisabled(true);
        } else {
            setSubmitDisabled(false);
        }
    }, [city, state, zipCode]);

    async function onSubmit() {
        setSubmitDisabled(true)
        setShowErrorBanner(false);
        const columnData = [
            {
                city
            },
            {
                state
            },
            {
                zipCode
            }
        ];
        const myInit = {
            body: {
                columnData
            }
        };

        try {

            await advisorProfileService.update(props.user.professionalId, myInit, firmAdminAuth);

            props.setUser({
                ...props.user,
                city,
                state,
                zipCode
            });
            setSubmitDisabled(false)
            props.onClose();
        } catch (error) {
            console.log(error);
            setErrors(error.toString());
            setShowErrorBanner(true);
            setSubmitDisabled(false)
        }
    }


    return (
        <>
            <div className={styles.intoFormContainer}>

                <div className="input-container justify-left" style={{ gap: '5px' }}>
                    <div className={styles.flexColumn}>
                        <label htmlFor="city">City</label>
                        <input
                            className="input"
                            placeholder="City"
                            type="text"
                            style={{ maxWidth: '350px', minWidth: '300px' }}
                            value={city}
                            onChange={e => setCity(e.target.value)}
                        />
                    </div>
                    <div className={styles.flexColumn}>
                        <label htmlFor="state">State</label>
                        <select
                            className="input"
                            value={state}
                            onChange={e => setState(e.target.value)}
                        >
                            <option value=''></option>
                            {states.map(key => (
                                <option value={key} key={key}>{key}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="input-container justify-left" style={{ gap: '5px' }}>
                    <div className={styles.flexColumn}>
                        <label htmlFor="city">Zip Code</label>
                        <input
                            className="input"
                            type="number"
                            placeholder="Zip Code"
                            style={{ maxWidth: '350px', minWidth: '300px' }}
                            value={zipCode}
                            onChange={e => setZipCode(e.target.value)}
                        />
                    </div>
                </div>
                {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}
            </div>
            <div className="modal-footer">
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>Cancel</Button>
                <Button className="modal-cancel-button" color="gradient-button" onClick={onSubmit} disabled={submitDisabled}><Check size={20} />Confirm</Button>
            </div>
        </>
    )
}

