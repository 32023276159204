import React from "react";
import styles from "./CompletenessScoreInfo.module.scss";
import { Info } from 'react-feather';


export default function CompletenessScoreInfo(props) {



    return (
        <>
            <div className={styles.formContainer}>
                <div className={styles.bannerContainer}>
                    <Info size={20} color="var(--adfi_green500)" /> <span className={styles.bannerText}>Score is determined by the completion of the Profile details below and contributes to Marketplace sorting.</span>
                </div>

                <div className={styles.completenessScoreInfoContainer}>
                    <div className={styles.percentagesContainer}>
                        <span className={`${styles.twentyPercent} ${styles.top}`}>20%</span>
                        <span className={styles.twentyPercent}>20%</span>
                        <span className={styles.tenPercent}>10%</span>
                        <span className={styles.tenPercent}>10%</span>
                        <span className={styles.tenPercent}>10%</span>
                        <span className={styles.tenPercent}>10%</span>
                        <span className={styles.tenPercent}>10%</span>
                        <span className={`${styles.tenPercent} ${styles.bottom}`}>10%</span>

                    </div>
                    <div className={styles.textContainer}>
                        <span>Profile Picture</span>
                        <span>Introduction</span>
                        <span>Services Provided</span>
                        <span>Specialties and Expertise</span>
                        <span>Clientele</span>
                        <span>Credentials and Designations</span>
                        <span>Pricing Models</span>
                        <span>Education</span>

                    </div>

                </div>

            </div>
        </>
    )
}
