import React from 'react';
import { ReviewsCarousel } from '../../components/ReviewsCarousel/ReviewsCarousel';
import '../Register/Register.scss';
import '@aws-amplify/ui-react/styles.css';
import SignInForm from './SignInForm';
import SEO from '../../components/SEO/seo';

export default function SignIn(props) {
    return (
        <>
            <SEO title={`Sign In to AdvisorFinder`} name='AdvisorFinder' type='website' canonical={`https://advisorfinder.com/app/signin`} />
            <div className='page-container'>
                <div className='register-container left'>
                    <div className='logo-container'>
                        <a href='https://www.advisorfinder.com' rel='noopener'>
                            <img loading='lazy' src='/app/AdFi-logo-name.svg' alt='/AdFi-logo-name.svg' className='register-logo' />
                        </a>
                    </div>
                    <SignInForm></SignInForm>
                </div>
                <div className='register-container right'>
                    <ReviewsCarousel />
                </div>
            </div>
        </>
    );
}
