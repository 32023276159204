import "./FormModal.scss";
import React from "react";
import ReactDOM from "react-dom";

function FormModalNotCloseable(props) {

    return ReactDOM.createPortal(
        <div className={`modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h4 className="modal-title">{props.title}</h4>
                </div>
                <div className="modal-body">
                    {React.cloneElement(props.children, { setUser: props.setUser })}
                </div>
            </div>
        </div>, document.getElementById("root")
    );
}

export default FormModalNotCloseable;