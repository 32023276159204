import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import styles from "./FirmAdminApply.module.scss";
import Button from '../../components/Button/Button'
import { ArrowRight, CheckSquare } from 'react-feather';
import '../Register/Register.scss'
import advisorProfileService from '../../services/advisorProfile.service';
import consumerProfileService from '../../services/consumerProfile.service';
import firmAdminService from '../../services/firmAdmin.service';
import ErrorBanner from '../../components/ErrorBanner/ErrorBanner';
import useMixPanelEvent from '../../Utils/MixPanel';
import { Search, XCircle } from 'react-feather';
import validateFirmAdminApply from '../../Utils/validateFirmAdminApply';


function FirmAdminApply() {

    const mixpanelTrack = useMixPanelEvent();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [firm, setFirm] = useState('');
    const [searchText, setSearchText] = useState('');
    const [firms, setFirms] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([...firms]);
    const [optionsIsOpen, setOptionsIsOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [errorBannerText, setErrorBannerText] = useState('Something went wrong. Please try again.')
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [betaAgreement, setBetaAgreement] = useState(false);
    const [feedbackConfirmed] = useState(false);
    // const feedbackOptions = ['LinkedIn', 'Twitter', 'Facebook', 'Google Search', 'Word of Mouth', 'Email', 'Other']

    useEffect(async () => {
        let firms = await firmAdminService.getFirms()
        setFirms(firms);
        setFilteredOptions(firms);
    }, [])

    useEffect(() => {
        if (firstName === '' || lastName === '' || email === '' || phoneNumber === '' || !firm) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }

    }, [firstName, lastName, email, phoneNumber, firm]);

    async function handleSubmit(event) {
        event.preventDefault();
        const validate = validateFirmAdminApply({ firstName, lastName, email, phoneNumber });

        if (Object.keys(validate).length === 0 && betaAgreement) {
            try {
                setDisabled(true);
                setShowErrorBanner(false);

                // check if advisor already exists 
                const [consumerResponse, advisorResponse, firmAdminResponse] = await Promise.all([
                    consumerProfileService.checkEmail(email),
                    advisorProfileService.checkEmail(email),
                    firmAdminService.checkEmail(email)
                ])
                if (!consumerResponse) { // email doesnt exist 

                    if (!advisorResponse && !firmAdminResponse) { // email doesnt exist 
                        mixpanelTrack('Advisor sign up', 'Email', email);
                        await apply();
                        setSubmitSuccess(true)
                    } else if (advisorResponse) {
                        setErrorBannerText('You have already applied as an advisor with this email.');
                        setShowErrorBanner(true);
                    }
                } else if (consumerResponse) {
                    setErrorBannerText('You have already applied as a consumer with this email. Please try a different email');
                    setShowErrorBanner(true);
                }
            } catch (error) {
                // set try again error
                setShowErrorBanner(true);
            } finally {
                setDisabled(false);
            }
        } else {
            setErrors(validate)
        }
    };

    async function apply() {
        try {
            const firmAdminInfo = {
                body: {
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    betaAgreement,
                    firmId: firm.id
                }
            };
            await firmAdminService.create(firmAdminInfo);
        } catch (error) {
            console.log('Error creating application: ', error);
            throw (new Error(error));
        }
    }

    function handleClickAway(e) {
        if (!e.relatedTarget) {
            setOptionsIsOpen(false);
        } else if (e.relatedTarget.id !== 'dropDownContainer') {
            setOptionsIsOpen(false);
        }
    }

    function handleChange(value) {
        setSearchText(value);
        filterOptions(value);
    }

    // function cancelButton() {
    //     props.onClose();
    //     setOptionsIsOpen(false);
    // }

    function filterOptions(value) {
        let tempData = []
        let tempFilterOptions = filteredOptions;
        tempData = tempFilterOptions.filter((filterData) => {
            if (filterData.name.toLowerCase().includes(value.toLowerCase())) {
                return filterData;
            }
            return false;

        });
        return tempData;
    }

    // const handleFeedback = (option) => {
    //     setFeedbackConfirmed(true);
    //     gaEventTrack('Discovery Type', { option })
    //     mixpanelTrack('Apply Feedback', 'Discovery Type', { option });
    // }

    return (
        <>
            <div className='page-container'>
                <div class="register-container left">
                    <div className='logo-container'>
                        <a href="https://www.advisorfinder.com" rel="noopener">
                            <img loading="lazy" src='/app/AdFi-logo-name.svg' alt="/AdFi-logo-name.svg" className='register-logo' />
                        </a>
                    </div>
                    {submitSuccess ?
                        <div className='form-container success'>
                            <img src="/app/sign-up-email.svg" alt="/sign-up-email.svg" />
                            <h1 className='register-header'>Thank you!</h1>
                            <p className='register-paragraph' style={{ marginBottom: '20px' }}>We have received your application and will review it soon. Check your email for status updates concerning your application.</p>

                            {feedbackConfirmed ?
                                <div style={{ marginTop: "70px" }}>
                                    <h2 style={{ marginBottom: "20px" }}>Thanks for letting us know!</h2>
                                    <CheckSquare size={60} color="green" />
                                </div>
                                :
                                <div>

                                </div>
                            }
                        </div>
                        :
                        firms && <div className='form-container apply'>
                            <h1 className='register-header'>Firm Admin Application</h1>
                            <p className='register-paragraph'>Do you already have an account? <Link to="/signin">Login Now</Link></p>
                            <form className="register-form" onSubmit={handleSubmit}>
                                <div className="input-container half-container">
                                    <input
                                        className="input half"
                                        type="text"
                                        id="firstName"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={e => setFirstName(e.target.value)}
                                    />
                                    <input
                                        className="input half"
                                        type="text"
                                        id="lastName"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)}
                                    />
                                </div>
                                {errors.name && <p className='error-message'>{errors.name}</p>}
                                <div className="input-container mobile-padding">
                                    <input
                                        type="email"
                                        className="input full"
                                        id="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value.replace(/\s/g, ''))}
                                    />
                                </div>
                                {errors.email && <p className='error-message'>{errors.email}</p>}
                                <div className="input-container">
                                    <input
                                        type="tel"
                                        className="input full"
                                        id="phoneNumber"
                                        placeholder="Enter phone number"
                                        value={phoneNumber}
                                        onChange={e => setPhoneNumber(e.target.value)}
                                    />
                                </div>
                                {errors.phoneNumber && <p className='error-message'>{errors.phoneNumber}</p>}

                                {!firm && <div className={styles.searchBarWrapper}>
                                    <Search className={styles.searchIcon} size={16} color="rgba(45, 43, 65, 1)" />
                                    <input className={styles.searchBar} placeholder={`Select Firm`} type="text" value={searchText}
                                        onChange={e => handleChange(e.target.value)} onFocus={() => setOptionsIsOpen(true)}
                                        onBlur={(e) => handleClickAway(e)} />
                                    <XCircle className={styles.cancelIcon} size={16} color="rgba(171, 170, 179, 1)" onClick={() => setSearchText('')} />
                                    {/* change to be when input is focused */}
                                    {(optionsIsOpen || searchText.length > 0) && <div className={styles.dropDownWrapper} id="dropDownContainer" tabIndex="0">
                                        {filterOptions(searchText).map((option, index) => (
                                            <div key={index} className={styles.option} onClick={() => setFirm(option)}>{option.name}</div>
                                        ))}

                                    </div>}
                                </div>}

                                {firm && <div className={styles.selectedServiceContainer}>
                                    <span>{firm.name}</span><XCircle className={styles.clearIcon} size={16} color="rgba(171, 170, 179, 1)" onClick={() => setFirm(null)}></XCircle>
                                </div>}

                                <div className="checkbox-container">
                                    <label className="optionRow">
                                        <input type="checkbox"
                                            value="betaAgreement"
                                            checked={betaAgreement}
                                            onChange={() => setBetaAgreement(!betaAgreement)} />
                                        <span className="checkmark"></span>
                                        <span>I have read the <a href='https://www.advisorfinder.com/license-agreement' target="_blank" rel="noreferrer">License Agreement</a> and agree to the detailed terms</span>
                                    </label>
                                </div>
                                <div className="input-container">
                                    <Button color="clear-button" type="submit" disabled={disabled || !betaAgreement}>Apply to AdvisorFinder <ArrowRight size={20} /></Button>
                                </div>
                            </form>
                            {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errorBannerText}</ErrorBanner>}
                        </div>
                    }


                </div>
                <div class="register-container right">
                    <div className='right-side-text'>
                        <h2 className='register-header'>Welcome to the modern way of growing your business</h2>
                        <p className='register-paragraph'>You’re one step away from beginning your digital business development journey. We're excited to help you elevate your entire online presence and start having future clients come to you!</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FirmAdminApply;
