import React, { useMemo } from 'react';

const RE_DIGIT = new RegExp(/^\d+$/);

function OtpCodeInput(props) {

    let otp = props.otp;
    
    const valueItems = useMemo(() => {
        const valueArray = otp.split('');
        const items = [];

        for (let i = 0; i < 6; i++) {
            const char = valueArray[i];

            if (RE_DIGIT.test(char)) {
                items.push(char);
            } else {
                items.push('');
            }
        }

        return items;
    }, [otp]);

    function focusToNextInput(target) {
        const nextElementSibling = target.nextElementSibling;

        if (nextElementSibling) {
            nextElementSibling.focus();
        }
    };

    function focusToPrevInput(target) {
        const previousElementSibling = target.previousElementSibling;

        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };

    function inputOnChange(e, idx) {

        let targetValue = e.target.value;

        const isTargetValueDigit = RE_DIGIT.test(targetValue);

        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }

        const nextInputEl = e.target.nextElementSibling;

        // only delete digit if next input element has no value
        if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
            return;
        }

        targetValue = isTargetValueDigit ? targetValue : ' ';

        const targetValueLength = targetValue.length;

        if (targetValueLength === 1) {
            const newValue = otp.substring(0, idx) + targetValue + otp.substring(idx + 1);

            props.setOtp(newValue);

            if (!isTargetValueDigit) {
                return;
            }

            focusToNextInput(e.target);

        } else if (targetValueLength === 6) {
            props.setOtp(targetValue);

            e.target.blur();
        }
    };

    function inputOnKeyDown(e) {
        const { key } = e;
        const target = e.target;

        if (key === 'ArrowRight' || key === 'ArrowDown') {
            e.preventDefault();
            return focusToNextInput(target);
        }

        if (key === 'ArrowLeft' || key === 'ArrowUp') {
            e.preventDefault();
            return focusToPrevInput(target);
        }

        target.setSelectionRange(0, target.value.length);

        if (e.key !== 'Backspace' || target.value !== '') {
            return;
        }

        focusToPrevInput(target);

    };

    function inputOnFocus(e) {
        const { target } = e;
        const prevInputEl = target.previousElementSibling;

        if (prevInputEl && prevInputEl.value === '') {
            return prevInputEl.focus();
        }
        target.setSelectionRange(0, target.value.length);
    };



    return (
        <div className="otp-container">
            {valueItems.map((digit, idx) => (
                <input
                    key={idx}
                    type="text"
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    pattern="\d{1}"
                    maxLength={6}
                    className="otp-input"
                    value={digit}
                    onChange={(e) => inputOnChange(e, idx)}
                    onKeyDown={inputOnKeyDown}
                    onFocus={inputOnFocus}
                />
            ))}
        </div>
    )
}

export default OtpCodeInput;
