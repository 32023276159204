import React from "react";
import styles from "./Filters.module.scss";
import { ICON_MAPPINGS } from "./FilterMappings";
export default function Industrytype(props) {

    const { currentIndustryType, setCurrentIndustryType, industryFilters } = props
    const type = industryFilters;

    const selectIndustryType = (id) => {
        if (currentIndustryType === id) {
            return setCurrentIndustryType(0)
        }

        setCurrentIndustryType(id)
    }

    function mapIcon(id) {
        const mapping =  ICON_MAPPINGS.find((mapping) => id === mapping.key)
        return mapping.icon
    }
    return (
        <>
            <div className={styles.formSection}>
                <h3>Industry Type</h3>
                <div className={styles.verticaloffsetmedium}>Adding an industry filter may help you find an advisor who understands the specific financial nuances, challenges, and opportunities unique to your field.</div>
                <div className={styles.industryTypesContainer}>
                    {type.map((item) => {
                        return <div className={currentIndustryType === item.id ? styles.industryTypeSelected : styles.industryType} key={item.id} onClick={() => selectIndustryType(item.id)}>{mapIcon(item.icon)}{item.value}</div>
                    })}
                </div>
            </div>
        </>
    )
}