import React from 'react'
import './Button.scss'

export default function Button(props) {
    const size = props.size || "regular";    //large
    const color = props.color ? props.color : "gradient-button";    //clear-button
    const flex = props.flex ? "flex" : "";
    const isDisabled = props.disabled || false;
    const type = props.type || "button";
    const boxShadow = props.boxShadow ? "box-shadow" : "";

    return (
        <button
        className={`${color} ${size} ${flex} ${boxShadow} ${props.className} nowrap`}
        disabled={isDisabled}
        type={type}
        onClick={props.onClick}>
            <div className="buttonChildren">{props.children}</div>
        </button>
    )
}
