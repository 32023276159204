import React from "react";
import styles from "./Filters.module.scss";

export default function HelpWith(props) {

    const { currentHelpWith, setCurrentHelpWith, helpWithFilters } = props;
    const options = helpWithFilters

    const selectHelpWith = (id) => {
        if (currentHelpWith?.includes(id) && id !== props.forceHelpWith) {
            const index = currentHelpWith.indexOf(id)
            currentHelpWith.splice(index, 1)
            return setCurrentHelpWith([...currentHelpWith])
        }
        return setCurrentHelpWith([...currentHelpWith, id])
    }

    return (
        <>
            <div className={styles.formSection}>
                <h3>What do you need help with?</h3>
                <div className={styles.verticaloffsetmedium}>Whether it's planning for a big purchase or managing unexpected wealth, pinpoint what you're looking for to find advisors.</div>
                <div className={styles.helpWithContainer}>
                    {options.map((item) => {
                        return <div className={currentHelpWith?.includes(item.id) ? styles.helpWithSelected : styles.helpWith} key={item.id} onClick={() => selectHelpWith(item.id)}><img className={styles.helpWithImg} src={`/app${item.icon}`} alt="" />{item.value}</div>
                    })}
                </div>
            </div>
        </>
    )
}