import validator from 'validator';

function validateConfirmPassword(values) {
    let errors = {};

    if(!validator.isEmail(values.email)){
        errors.email = 'Enter valid email'
    }

    // if (values.password !== values.confirmPassword) {
    //     errors.password = 'Passwords do not match!'
    // } else if(!validator.isStrongPassword(values.password)) {
    //     errors.password = 'Password does not meet requirements'
    // }

    return errors;
}

export default validateConfirmPassword;