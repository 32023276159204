import React, { useState, useEffect } from 'react';
import styles from './DisclosureTextForm.module.scss';
import { Info, Check } from 'react-feather';
import Button from '../../Button/Button';
import advisorProfileService from '../../../services/advisorProfile.service';
import ErrorBanner from '../../ErrorBanner/ErrorBanner';
import RSNIPDisclosureText from '../../Disclosures/RSNIPDisclosureText';

export default function DisclosureTextForm(props) {
    const [characterCount, setCharacterCount] = useState(0);
    const [disclosureText, setDisclosureText] = useState(props.user.disclosureText ? props.user.disclosureText.toString() : '');
    const [submitDisabled, setSubmitDisabled] = useState(props.user.disclosureText ? props.user.disclosureText?.toString().length <= 0 : true);
    const [rsnipChecked, setRsnipChecked] = useState(props.user.rsnipDisclosure);
    const [memberFINRASIPC, setMemberFINRASIPC] = useState(props.user.finraMember);

    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);

    const firmAdminAuth = props.firmAdminAuth;

    useEffect(() => {
        handleChange(disclosureText);
    }, [disclosureText]);

    useEffect(() => {
        console.log(`rsnipCheckbox ${rsnipChecked}`)
        props.user.rsnipDisclosure = rsnipChecked;
    }, [rsnipChecked]);

    function handleChange(value) {
        setCharacterCount(value.length);
        if (value.length <= 2500) {
            setDisclosureText(value);
        }
        if (value.length > 0) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    }

    function cancelButton() {
        props.user.disclosureText ? setDisclosureText(props.user.disclosureText.toString()) : setDisclosureText('');
        props.onClose();
    }

    async function onSubmit() {
        setSubmitDisabled(true);
        setShowErrorBanner(false);

        const advisorColumnData = [
            {
                disclosureText: disclosureText,
            },
            {
                rsnipDisclosure: rsnipChecked,
            },
            {
                finraMember: memberFINRASIPC,
            },
        ];

        const myInit = {
            body: {
                columnData: advisorColumnData,
            },
        };

        try {
            let response = await advisorProfileService.update(props.user.professionalId, myInit, firmAdminAuth);

            props.setUser({
                ...props.user,
                disclosureText: response.disclosureText,
                rsnipDisclosure: rsnipChecked,
                finraMember: memberFINRASIPC,
            });
            setSubmitDisabled(false);
            props.onClose();
        } catch (error) {
            setSubmitDisabled(false);
            setErrors(error.toString());
            setShowErrorBanner(true);
            console.log(error);
        }
    }

    return (
        <>
            <div className={styles.intoFormContainer}>
                <div className={styles.bannerContainer}>
                    <Info size={20} color='var(--adfi_green500)' /> <span className={styles.bannerText}>Provide any needed advisor disclosure</span>
                </div>
                <div className={styles.introForm}>
                    <label htmlFor='bioText'>Disclosure Text</label>
                    <textarea id='bioText' className={styles.infoTextArea} onChange={(e) => handleChange(e.target.value)} value={disclosureText}></textarea>
                    <p className={styles.characterCount}>{characterCount} / 2500</p>
                </div>

                <label className={styles.optionRow}>
                    <input value='Add Member FINRA/SIPC?' checked={memberFINRASIPC} onChange={(e) => setMemberFINRASIPC(e.target.checked)} type='checkbox' />
                    <span className={styles.checkmark}></span>
                    <span>
                        Add "Member{' '}
                        <a href='https://www.finra.org/#/' target='_blank' rel='noreferrer'>
                            FINRA
                        </a>
                        /
                        <a href='https://www.sipc.org/' target='_blank' rel='noreferrer'>
                            SIPC
                        </a>
                        " to your personal disclosure?
                    </span>
                </label>

                <label className={styles.optionRow}>
                    <input value='Add RSNIP Disclosure?' checked={rsnipChecked} onChange={(e) => setRsnipChecked(e.target.checked)} type='checkbox' />
                    <span className={styles.checkmark}></span>
                    <span>Add RSNIP Disclosure?</span>
                </label>

                {rsnipChecked && <RSNIPDisclosureText advisor={props.user} />}
            </div>
            {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}

            <div className='modal-footer'>
                <Button className='modal-cancel-button' color='clear-button' onClick={() => cancelButton()}>
                    Cancel
                </Button>
                <Button className='modal-cancel-button' color='gradient-button' onClick={onSubmit} disabled={submitDisabled}>
                    <Check size={20} />
                    Confirm
                </Button>
            </div>
        </>
    );
}
