import React, {useState} from "react";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
  } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';

function ImageCropper(props) {
    const {imageToCrop, onImageCropped} = props;
    const [aspect] = useState(1 / 1)
    const [crop, setCrop] = useState(undefined)


    function centerAspectCrop(
        mediaWidth,
        mediaHeight,
        aspect,
      ) {
        return centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
          ),
          mediaWidth,
          mediaHeight,
        )
      }

      function onImageLoad(e) {
        if (aspect) {
          const { width, height } = e.currentTarget
          setImageRef(e.currentTarget);
          setCrop(centerAspectCrop(width, height, aspect))
        }
      }

    const [imageRef, setImageRef] = useState();

    async function cropImage(crop) {
        if (imageRef && crop.width && crop.height) {
            const croppedImage = await getCroppedImage(
                imageRef,
                crop,
                'croppedImage.jpeg' // destination filename
            );

            const croppedBlob = await getCroppedBlob(
                imageRef,
                crop
            )

            // calling the props function to expose
            // croppedImage to the parent component
            onImageCropped(croppedImage, croppedBlob);
        }
    }

    function getCroppedImage(sourceImage, cropConfig, fileName) {
        // creating the cropped image from the source image
        const canvas = document.createElement('canvas');
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;
        canvas.width = cropConfig.width;
        canvas.height = cropConfig.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            sourceImage,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            cropConfig.width,
            cropConfig.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    // returning an error
                    if (!blob) {
                        reject(new Error('Canvas is empty'));
                        return;
                    }

                    blob.name = fileName;
                    // creating a Object URL representing the Blob object given
                    const croppedImageUrl = window.URL.createObjectURL(blob);
                    resolve(croppedImageUrl);
                }, 'image/jpeg'
            );
        });
    }

    function getCroppedBlob(sourceImage, cropConfig) {
        // creating the cropped image from the source image
        const canvas = document.createElement('canvas');
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;
        canvas.width = cropConfig.width;
        canvas.height = cropConfig.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            sourceImage,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            cropConfig.width,
            cropConfig.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    // returning an error
                    if (!blob) {
                        reject(new Error('Canvas is empty'));
                        return;
                    }
                    resolve(blob);
                }
            );
        });
    }

    return (
        <ReactCrop
            src={imageToCrop}
            crop={crop}
            onComplete={(cropConfig) => cropImage(cropConfig)}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            aspect={aspect}
            crossorigin="anonymous" // to avoid CORS-related problems
        >
            <img
            ref={imageRef}
            alt="Crop me"
            src={imageToCrop}
            onLoad={onImageLoad}
          />
        </ReactCrop>
    );
}

ImageCropper.defaultProps = {
    onImageCropped: () => {}
}

export default ImageCropper;