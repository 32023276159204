import { get, put, post } from 'aws-amplify/api';

const firmAdminService = {
  update,
  getOne,
  checkEmail,
  getAll,
  create,
  getFirms,
  getAFirm,
  createFirm,
  getFirmAdvisors,
};

const apiName = 'FirmAdminAPI';
const apiPath = '/firmAdmin';

//FirmAdmin
async function create(myInit) {
  const path = apiPath;
  const options = myInit;
  return handleAPICall(post({ apiName, path, options }));
}

async function update(params, myInit) {
  const path = `${apiPath}/update/${params}`;
  const options = addCurrentUserInfo(myInit);
  return handleAPICall(put({ apiName, path, options }));
}

async function checkEmail(params) {
  const path = `${apiPath}/userExists/${params}`;
  return handleAPICall(get({ apiName, path }));
}

async function getOne(params) {
  const path = `${apiPath}/${params}`;
  return handleAPICall(get({ apiName, path }));
}

async function getAll(params) {
  const path = `${apiPath}/getAll${params ? params : ''}`;
  return handleAPICall(get({ apiName, path }));
}

//Firms

async function getFirms(params) {
  const path = `${apiPath}/firm${params ? params : ''}`;
  return handleAPICall(get({ apiName, path }));
}

async function createFirm(myInit) {
  const path = `${apiPath}/firm/create`;
  const options = myInit;
  return handleAPICall(post({ apiName, path, options }));
}

async function getAFirm(params) {
  const path = `${apiPath}/firm/${params}`;
  return handleAPICall(get({ apiName, path }));
}

async function getFirmAdvisors(id) {
  const path = `${apiPath}/firm/advisors/${id}`;
  return handleAPICall(get({ apiName, path }));
}

async function handleAPICall(fnc) {
  const { body } = await fnc.response;
  const json = body.json();
  return json;
}

function addCurrentUserInfo(myInit) {
  myInit.body.currentUserId = JSON.parse(localStorage.getItem('user')).id;
  myInit.body.userGroups = JSON.parse(localStorage.getItem('user')).groups;
  return myInit;
}

export default firmAdminService;
