/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styles from './EducationAddForm.module.scss';
import { Search, Check, XCircle, Trash2 } from 'react-feather';
import Button from '../../Button/Button';
import advisorProfileService from '../../../services/advisorProfile.service';
import useDebounce from '../../../hooks/useDebounce';
import getYears from '../../../Utils/getYears';
import ErrorBanner from '../../ErrorBanner/ErrorBanner';

export default function EducationAddForm(props) {
    const data = props.data ? props.data : [];
    const years = getYears();

    const [searchText, setSearchText] = useState('');
    const debouncedSearchTerm = useDebounce(searchText, 500);
    const [searchResults, setSearchResults] = useState([]);

    const [selectedUniversity, setSelectedUniversity] = useState('');
    const [selectedUniversityId, setSelectedUniversityId] = useState(null);
    const [completedDate, setCompletedDate] = useState(null);
    const [degreeInfo, setDegreeInfo] = useState('');
    const [characterCount, setCharacterCount] = useState(0);

    const [editEducation, setEditEducation] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [optionsIsOpen, setOptionsIsOpen] = useState(false);

    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const firmAdminAuth = props.firmAdminAuth;

    useEffect(async () => {
        setShowErrorBanner(false);
        try {
            const response = await advisorProfileService.universityOptions(debouncedSearchTerm);

            if (response.length > 0) {
                setSearchResults(response);
            } else {
                setSearchResults([]);
            }
        } catch (err) {
            setErrors(err);
            setShowErrorBanner(true);
            console.log(err);
        }
    }, [debouncedSearchTerm]);

    useEffect(async () => {
        if (props.selectedUniversity) {
            setSelectedUniversity(props.selectedUniversity.institution);
            if (!props.selectedUniversity.educationId) {
                const response = await advisorProfileService.universityOptions(props.selectedUniversity.institution);
                setSelectedUniversityId(response.at(0)?.educationId);
            } else {
                setSelectedUniversityId(props.selectedUniversity.educationId);
            }

            setCompletedDate(props.selectedUniversity.completedDate);
            setDegreeInfo(props.selectedUniversity.degreeObtained);
            setCharacterCount(props.selectedUniversity.degreeObtained.length);
            setEditEducation(true);
        } else {
            setSelectedUniversity('');
            setCompletedDate(null);
            setDegreeInfo('');
            setCharacterCount(0);
            setEditEducation(false);
        }
    }, [props.selectedUniversity]);

    const handleChange = (event) => {
        setSearchText(event.target.value);
    };

    function handleClickAway(e) {
        if (!e.relatedTarget) {
            setOptionsIsOpen(false);
        } else if (e.relatedTarget.id !== 'dropDownContainer') {
            setOptionsIsOpen(false);
        }
    }

    function handleDegreeInfo(value) {
        setCharacterCount(value.length);
        if (value.length <= 2500) {
            setDegreeInfo(value);
        }
    }

    function cancelButton() {
        setSelectedUniversity('');
        setCompletedDate(null);
        setDegreeInfo('');
        setCharacterCount(0);
        setOptionsIsOpen(false);
        props.onClose();
    }

    function clearDates() {
        setCompletedDate(null);
    }

    function setCompletedDateInput(value) {
        setCompletedDate(value);
    }

    function setSelectedUniversityInput(value) {
        setSelectedUniversity(value.name);
        setSelectedUniversityId(value.educationId);
    }

    function displayDate(date) {
        if (date) {
            if (date.length > 4) {
                return new Date(date).getFullYear();
            } else {
                return date;
            }
        } else {
            return 'N/A';
        }
    }

    function checkDisabled() {
        if (selectedUniversity && completedDate && degreeInfo) {
            return false;
        } else {
            return true;
        }
    }

    async function onSubmit(isDelete) {
        setSubmitDisabled(true);
        setShowErrorBanner(false);

        const submitValue = {
            institution: selectedUniversity,
            completedDate: completedDate,
            degreeObtained: degreeInfo,
            educationId: selectedUniversityId,
        };

        let columnData = {};

        if (editEducation && props.selectedUniversity) {
            let newData = [];
            if (isDelete) {
                newData = data.filter((el, index) => {
                    return index !== props.selectedUniversity.index;
                });
            } else {
                newData = data.map((education, index) => {
                    if (index === props.selectedUniversity.index) {
                        return {
                            educationId: education.educationId ?? selectedUniversityId,
                            institution: selectedUniversity,
                            completedDate: completedDate,
                            degreeObtained: degreeInfo,
                        };
                    }
                    return education;
                });
            }
            props.setData(newData);

            columnData = [
                {
                    education: newData,
                },
            ];
        } else {
            columnData = [
                {
                    education: [...data, submitValue],
                },
            ];
            props.setData([...data, submitValue]);
        }

        const myInit = {
            body: {
                columnData,
            },
        };

        setOptionsIsOpen(false);

        try {
            await Promise.all([advisorProfileService.update(props.advisor.professionalId, myInit, firmAdminAuth), props.updateCompletenessScore()]);
            setSubmitDisabled(false);
            props.onClose();
        } catch (error) {
            setSubmitDisabled(false);
            setErrors(error.toString());
            setShowErrorBanner(true);
            console.log(error);
        }
    }

    return (
        <>
            <div className={styles.formContainer}>
                {!selectedUniversity && (
                    <div className={styles.searchBarWrapper}>
                        <Search className={styles.searchIcon} size={16} color='rgba(45, 43, 65, 1)' />

                        <input
                            className={styles.searchBar}
                            placeholder={`Start typing Education`}
                            type='text'
                            value={searchText}
                            onChange={(e) => handleChange(e)}
                            onFocus={() => setOptionsIsOpen(true)}
                            onBlur={(e) => handleClickAway(e)}
                        />
                        <XCircle className={styles.cancelIcon} size={16} color='rgba(171, 170, 179, 1)' onClick={() => setSearchText('')} />
                        {/* change to be when input is focused */}
                        {(optionsIsOpen || searchText.length > 0) && (
                            <div className={styles.dropDownWrapper} id='dropDownContainer' tabIndex='0'>
                                {searchResults.map((option, index) => (
                                    <div key={index} className={styles.option} onClick={() => setSelectedUniversityInput(option)}>
                                        {option.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}

                {selectedUniversity && (
                    <>
                        <div>
                            <label htmlFor='university'>College or University</label>
                            <div className={styles.selectedServiceContainer} id='university'>
                                <span>{selectedUniversity}</span>
                                {<XCircle className={styles.clearIcon} size={16} color='rgba(171, 170, 179, 1)' onClick={() => setSelectedUniversity('')}></XCircle>}
                            </div>
                        </div>

                        {completedDate ? (
                            <div>
                                <label htmlFor='completedDate'>Year Obtained</label>
                                <div className={styles.selectedServiceContainer} id='completedDate'>
                                    <span>{displayDate(completedDate)}</span>
                                    {<XCircle className={styles.clearIcon} size={16} color='rgba(171, 170, 179, 1)' onClick={() => clearDates()}></XCircle>}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <label htmlFor='state'>Year Obtained</label>
                                <select className={styles.yearPicker} value={completedDate} onChange={(e) => setCompletedDateInput(e.target.value)}>
                                    <option value=''></option>
                                    {years.map((key) => (
                                        <option value={key} key={key}>
                                            {key}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className={styles.introForm}>
                            <label htmlFor='degreeInfo'>Degree Obtained</label>
                            <textarea id='degreeInfo' className={styles.infoTextArea} onChange={(e) => handleDegreeInfo(e.target.value)} value={degreeInfo}></textarea>
                            <p className={styles.characterCount}>{characterCount} / 2500</p>
                        </div>
                    </>
                )}
            </div>
            {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}
            <div className='modal-footer'>
                {editEducation && (
                    <>
                        <Button className={styles.removeButton} color='clear-button' disabled={!selectedUniversity || submitDisabled} onClick={() => onSubmit(true)}>
                            <Trash2 size={20} />
                            Delete
                        </Button>
                        <div className={styles.modalButtons}>
                            <Button className='modal-cancel-button' color='clear-button' onClick={() => cancelButton()}>
                                Cancel
                            </Button>
                            <Button className='modal-cancel-button' color='gradient-button' disabled={!selectedUniversity || submitDisabled} onClick={() => onSubmit(false)}>
                                <Check size={20} />
                                Confirm
                            </Button>
                        </div>
                    </>
                )}
                {!editEducation && (
                    <>
                        <Button className='modal-cancel-button' color='clear-button' onClick={() => cancelButton()}>
                            Cancel
                        </Button>
                        <Button className='modal-cancel-button' color='gradient-button' disabled={checkDisabled()} onClick={() => onSubmit(false)}>
                            <Check size={20} />
                            Confirm
                        </Button>
                    </>
                )}
            </div>
        </>
    );
}
