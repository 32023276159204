import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
Amplify.configure(
  {
    Auth: {
      Cognito: {
        allowGuestAccess: true,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID ?? 'us-east-1:0400cd4c-3430-4b7c-9b65-80fc7af65783', //'us-east-1:8c11d148-8b9f-41c5-9154-2fa47393c42a', // REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-1', // REQUIRED - Amazon Cognito Region
        userPoolId: process.env.REACT_APP_USER_POOL_ID ?? 'east-1_yTdZiyVZ0', //'us-east-1_0snJCZEda', // OPTIONAL - Amazon Cognito User Pool ID
        userPoolClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT ?? 'oi5j5n8mtvmlua34m85hbc4ml', //'2cn92d7roeqg3l9rkr0n0ugs5m', // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      },
    },
    API: {
      REST: {
        ProfessionalsAPI: {
          endpoint: process.env.REACT_APP_PROFESSIONAL_API_ENDPOINT ?? 'https://f9qt3vc39j.execute-api.us-east-1.amazonaws.com/prod',
          region: 'us-east-1',
          paths: ['/'],
        },
        ConsumersAPI: {
          endpoint: process.env.REACT_APP_CONSUMER_API_ENDPOINT ?? 'https://q13i7u7tkd.execute-api.us-east-1.amazonaws.com/prod',
          region: 'us-east-1',
          paths: ['/'],
        },
        FirmAdminAPI: {
          endpoint: process.env.REACT_APP_FIRMADMIN_API_ENDPOINT ?? 'https://q13i7u7tkd.execute-api.us-east-1.amazonaws.com/prod',
          region: 'us-east-1',
          paths: ['/'],
        },
      },
    },
    Storage: {
      S3: {
        region: 'us-east-1',
        bucket: process.env.REACT_APP_STORAGE_BUCKET_NAME ?? 'amplify-profile-pictures94201-dev',
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID ?? 'us-east-1:0400cd4c-3430-4b7c-9b65-80fc7af65783', // 'us-east-1:8c11d148-8b9f-41c5-9154-2fa47393c42a'
      },
    },
  }
);
Amplify.getConfig();

// const root = ReactDOM.createRoot(document.getElementById('root'));
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
