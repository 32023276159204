import './NavBar.scss';
import { signOut } from 'aws-amplify/auth';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../Utils/UserContext';
import { Menu, Search, UserPlus, Settings, ArrowRight, Grid, Tool, Inbox, User } from 'react-feather';
import Button from '../../components/Button/Button';
import useGetProfilePicture from '../../hooks/useGetProfilePicture';
import { fetchAuthSession } from 'aws-amplify/auth';

function NavBar(props) {
    const { user, setUser } = useContext(UserContext);
    const [hamburger, setHamburger] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [userGroup] = useState(user ? JSON.parse(localStorage.getItem('user')).groups : 'none');
    const [isAdvisor, setIsAdvisor] = useState(false);
    const [isFirmAdmin, setIsFirmAdmin] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);
    const showHamburger = () => setHamburger(!hamburger);

    const profilePicture = useGetProfilePicture(
        user
            ? JSON.parse(localStorage.getItem('user')).firmId && JSON.parse(localStorage.getItem('user')).firmId > 0
                ? JSON.parse(localStorage.getItem('user')).professionalId
                : JSON.parse(localStorage.getItem('user')).userName
            : ''
    );
    const navigate = useNavigate();

    useEffect(async () => {
        const usersession = await fetchAuthSession();
        if (usersession.tokens && !user) {
            console.log('signing user out..');
            await signOut();
        }
    }, []);

    useEffect(() => {
        if (userGroup.includes('Advisor')) {
            setIsAdvisor(true);
        } else if (userGroup.includes('FirmAdmin')) {
            setIsFirmAdmin(true);
        }
    }, [userGroup]);

    const handleLogin = async () => {
        setHamburger(false);

        if (user) {
            await signOut();
            setUser(null);
            navigate('/signin');
        } else {
            navigate('/signin');
        }
    };

    return (
        <>
            <div className='top-nav'>
                <a href='https://www.advisorfinder.com/'>
                    <img src='/app/AdFi-logo-name.svg' loading='eager' alt='Adfi_logo' />
                </a>
            </div>

            <nav className='ham-nav-bar'>
                <a href='https://www.advisorfinder.com/'>
                    <img src='/app/AdFi-logo-name.svg' loading='lazy' alt='adfi_logo' className='ham-nav-logo' />
                </a>
                <div className='hamburger-menu'>
                    <Button className='ham-nav-button' color='clear-button no-border' onClick={showHamburger}>
                        <Menu size={28} />
                    </Button>
                </div>
                {hamburger && (
                    <ul className='hamburger-links'>
                        <li className='ham-link'>
                            <Link to='/marketplace' onClick={showHamburger}>
                                Marketplace
                            </Link>
                        </li>
                        {user && (
                            <li className='ham-link'>
                                <Link to={isAdvisor ? '/advisor-dashboard' : isFirmAdmin ? '/firmAdmin-dashboard' : '/dashboard'} onClick={showHamburger}>
                                    Dashboard
                                </Link>
                            </li>
                        )}
                        {user && !isFirmAdmin && (
                            <li className='ham-link'>
                                <Link to={isAdvisor ? `/advisor-profile/${user.id}/${user.firstName}-${user.lastName}/` : '/consumer-profile'} onClick={showHamburger}>
                                    My Profile
                                </Link>
                            </li>
                        )}
                        {user && (
                            <li className='ham-link'>
                                <Link to={'/account-settings'} onClick={showHamburger}>
                                    Settings
                                </Link>
                            </li>
                        )}
                        {userGroup.includes('Admin') && (
                            <li className='ham-link'>
                                <Link to={'/admin-dashboard'} onClick={showHamburger}>
                                    Admin Dashboard
                                </Link>
                            </li>
                        )}
                        <hr className='ham-link-line' />
                        <li className='ham-link'>
                            <a className='ham-link' onClick={handleLogin}>
                                {user ? 'Log out' : 'Log in'}
                            </a>
                        </li>
                        {!user && (
                            <li className='ham-link'>
                                <Link to='/signup' onClick={showHamburger}>
                                    Sign up
                                </Link>
                            </li>
                        )}
                        <li className='ham-link'>
                            <a className='ham-link' href='https://airtable.com/shr3HhoVU1nilT5o9' target='_blank' rel='noreferrer'>
                                Feedback
                            </a>
                        </li>
                    </ul>
                )}
            </nav>
            <nav className={sidebar ? 'navbar open' : 'navbar'} onMouseEnter={showSidebar} onMouseLeave={showSidebar}>
                <div className='nav-button-wrapper'>
                    {user && !isFirmAdmin && (
                        <Link
                            className={`nav-button ${sidebar ? 'open' : ''} ${user ? '' : 'none'}`}
                            to={isAdvisor ? `/advisor-profile/${user.id}/${user.firstName}-${user.lastName}/` : '/consumer-profile'}>
                            {profilePicture && isAdvisor && <img loading='lazy' className={`profilePicture pupleBorder`} src={profilePicture} alt='profile_picture'></img>}
                            {!isAdvisor && <User className={'navIconSpacer'} size={20} />}
                            {sidebar ? <span>My Profile</span> : ''}
                        </Link>
                    )}
                    <Link className={`nav-button ${sidebar ? 'open' : ''}`} to='/marketplace'>
                        <Search size={20} className={'navIconSpacer'} />
                        {sidebar ? <span>Marketplace</span> : ''}
                    </Link>
                    {user && (
                        <Link className={`nav-button ${sidebar ? 'open' : ''} ${user ? '' : 'none'}`} to={isAdvisor ? '/advisor-dashboard' : isFirmAdmin ? '/firmAdmin-dashboard' : '/dashboard'}>
                            <Grid size={20} className={'navIconSpacer'} />
                            {sidebar ? <span>Dashboard</span> : ''}
                        </Link>
                    )}
                    {user && (
                        <Link className={`nav-button ${sidebar ? 'open' : ''} ${user ? '' : 'none'}`} to={'/account-settings'}>
                            <Settings size={20} className={'navIconSpacer'} />
                            {sidebar ? <span>Settings</span> : ''}
                        </Link>
                    )}
                    {userGroup.includes('Admin') && (
                        <Link className={`nav-button ${sidebar ? 'open' : ''} ${user ? '' : 'none'}`} to={'/admin-dashboard'}>
                            <Tool size={20} className={'navIconSpacer'} />
                            {sidebar ? <span>Admin Dash</span> : ''}
                        </Link>
                    )}
                    <div className={`nav-button ${sidebar ? 'open' : ''}`} onClick={handleLogin}>
                        <ArrowRight size={20} className={'navIconSpacer'} />
                        {sidebar ? <span>{user ? 'Log out' : 'Log in'}</span> : ''}
                    </div>
                    {!user && (
                        <Link className={`nav-button ${sidebar ? 'open' : ''}`} to={'/signup'}>
                            <UserPlus size={20} className={'navIconSpacer'} />
                            {sidebar ? <span>Sign up</span> : ''}
                        </Link>
                    )}
                    <a href='https://airtable.com/shr3HhoVU1nilT5o9' target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }}>
                        <div className={`nav-button ${sidebar ? 'open' : ''}`}>
                            <Inbox size={20} className={'navIconSpacer'} />
                            {sidebar ? <span>Feedback</span> : ''}
                        </div>
                    </a>
                </div>
            </nav>
            <div className='vertical-page-offset'>{props.children}</div>
        </>
    );
}

export default NavBar;
