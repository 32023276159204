import React, { useState, useEffect } from "react";
import styles from "./Education.module.scss";
import { ChevronUp, Plus, Edit2 } from 'react-feather';

const classnames = require('classnames');

export default function Education(props) {

    const editMode = props.editMode;
    const data = props.data;

    const [showMore, setShowMore] = useState(true);

    useEffect(() => {
        if (editMode) setShowMore(true)
    }, [editMode])

    function displayDate(date) {
        if (date) {
            if(date.length > 4) {
                return new Date(date).getFullYear()
            } else {
                return date
            }
          
        }
        else {
            return 'N/A'
        }
    }


    return (
        <>
          
            <div className={styles.servicesContainer_edit}>
                <div className={styles.servicesHeader_edit}><ChevronUp className={classnames(styles.showMoreArrow, showMore && styles.open)} onClick={() => setShowMore(!showMore)} size={20} color="rgba(45, 43, 65, 1)" /><h2>Education</h2><div className={styles.countNumber}>{data.length}</div>
                    {editMode && <div className={styles.addIcons} onClick={props.onClick}><Plus size={16} color="rgba(129, 128, 141, 1)"/></div>}
                </div>
                <div className={classnames(styles.dataContainer_edit, !showMore && styles.clamp)}>
                    {data.map((education, index) => (
                        <div className={`${styles.educationContainer}`} key={index}>
                            {editMode && <Edit2 size={16} className={styles.editPencil} onClick={() => { props.onEdit(education, index) }}></Edit2>}
                            <div className={styles.serviceText_edit}>
                                <span className={styles.serviceName}>{education.institution}</span>
                                <span>{education.degreeObtained}</span>
                                <span>Year Obtained: {displayDate(education.completedDate)}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </>
    )
}