/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { Grid } from 'react-feather';
import { Filter, Search, XCircle, ChevronDown } from 'react-feather';
import FormModal from '../../components/Modals/FormModal';
import Filters from './Filters/Filters';
import CurrentFilters from './CurrentFilters';
import NoResults from './NoResults';
import MarketplaceAdvisors from './MarketplaceAdvisors/MarketplaceAdvisors';
import styles from './Marketplace.module.scss';
import advisorProfileService from '../../services/advisorProfile.service';
import ClipLoader from 'react-spinners/ClipLoader';
import ErrorBanner from '../../components/ErrorBanner/ErrorBanner';
import Button from '../../components/Button/Button';
import useDebounce from '../../hooks/useDebounce';
import GlobalFooter from '../../components/GlobalFooter/GlobalFooter';
import useMixPanelEvent from '../../Utils/MixPanel';
import SEO from '../../components/SEO/seo';
import { states, statesFull } from '../../constants/states';


import { Context } from '../../Utils/GlobalStore/Store';
import { HELP_WITH_FILTERS, INDUSTRY_MAPPINGS, LIFESTAGE_MAPPINGS } from './Filters/FilterMappings';

const classnames = require('classnames');

function StateSearchPage() {
    const mixpanelTrack = useMixPanelEvent();

    let { stateSearch } = useParams()
    let stateIndex = states.findIndex((x) => x === stateSearch.toUpperCase());
    if (!stateIndex) {
        window.location.replace('/app/marketplace')
    } 

    const [state, dispatch] = useContext(Context);

    const [helpWithFilters, setHelpWithFilters] = useState(HELP_WITH_FILTERS);
    const [industryFilters, setIndustryFilters] = useState(INDUSTRY_MAPPINGS);
    const [lifeStageFilters, setLifestageFilters] = useState(LIFESTAGE_MAPPINGS);

    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(searchParams.get('page') ?? 0);
    const [currentOffSet, setCurrentOffSet] = useState(parseInt(searchParams.get('offset') ?? 0));
    const [currentState, setCurrentState] = useState(stateSearch.toUpperCase());
    //const [currentAccountSize, setCurrentAccountSize] = useState(searchParams.get('accountSize') ? parseInt(searchParams.get('accountSize')) : 0);
    const [currentIndustryType, setCurrentIndustryType] = useState(searchParams.get('industryType') ? parseInt(searchParams.get('industryType')) : 0);
    const [currentLifeStage, setCurrentLifeStage] = useState(searchParams.get('lifeStage') ? parseInt(searchParams.get('lifeStage')) : 0);
    const [currentHelpWith, setCurrentHelpWith] = useState(
        searchParams.get('helpWith')
            ? searchParams
                  .get('helpWith')
                  .split(',')
                  .map((item) => parseInt(item))
            : []
    );
    const [currentFiltersArray, setCurrentFiltersArray] = useState([currentState, currentIndustryType, currentLifeStage, currentHelpWith]);
    const [textSearch, setTextSearch] = useState(searchParams.get('search') ? searchParams.get('search') : '');
    const debouncedSearchTerm = useDebounce(textSearch, 500);
    const [clearText, setClearText] = useState(false);

    const [currentPageAdvisors, setCurrentPageAdvisors] = useState(null);
    const [totalAdvisors, setTotalAdvisors] = useState(0);
    const [showFiltersModal, setShowFiltersModal] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [error, setError] = useState('');

    const [isLocationSort, setIsLocationSort] = useState(false);
    const [lat, setLat] = useState(null);
    const [lon, setLon] = useState(null);


    const onPageLoad = async (offset) => {
        let queryBody = {
            body: {
                state: currentState,
                //accountSize: currentAccountSize,
                industryType: currentIndustryType,
                lifeStage: currentLifeStage,
                helpWith: currentHelpWith,
                currentOffSet: offset ?? currentOffSet,
                search: textSearch,
                position: isLocationSort
                    ? {
                          lat: lat,
                          lon: lon,
                      }
                    : null,
            },
        };
        await getResults(queryBody);
    };

    useEffect(() => {
        const getMarketplaceMappings = async () => {
            if (state.marketplaceMappings.length <= 0) {
                const marketplaceMappings = await advisorProfileService.getMarketplaceMappings();
                dispatch({
                    type: 'SET_MARKETPLACE_MAPPINGS',
                    payload: marketplaceMappings,
                });
                setIndustryFilters(marketplaceMappings.find((config) => config.id === 'INDUSTRY_MAPPINGS').mappings);
                setLifestageFilters(marketplaceMappings.find((config) => config.id === 'LIFESTAGE_MAPPINGS').mappings);
                setHelpWithFilters(marketplaceMappings.find((config) => config.id === 'NEEDSTATE_MAPPINGS').mappings);
            } else {
                setIndustryFilters(state.marketplaceMappings.find((config) => config.id === 'INDUSTRY_MAPPINGS').mappings);
                setLifestageFilters(state.marketplaceMappings.find((config) => config.id === 'LIFESTAGE_MAPPINGS').mappings);
                setHelpWithFilters(state.marketplaceMappings.find((config) => config.id === 'NEEDSTATE_MAPPINGS').mappings);
            }
        };
        onPageLoad();
        getMarketplaceMappings();
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    const updateMedia = () => {
        setShowFiltersModal(false);
    };

    useEffect(() => {
        if (debouncedSearchTerm?.length > 0 || clearText) {
            setCurrentPage(0);
            setCurrentOffSet(0);
            searchParams.delete('page');
            searchParams.delete('offset');
            setSearchParams(searchParams);
            onPageLoad(0);
        }
    }, [debouncedSearchTerm]);

    async function handleLocationSortChange(value) {
        setIsLocationSort(value);
        setCurrentPage(0);
        setCurrentOffSet(0);
        searchParams.delete('page');
        searchParams.delete('offset');
        setSearchParams(searchParams);
        if (value) {
            await runLocationSearch();
        } else {
            let queryBody = {
                body: {
                    state: currentState,
                    //accountSize: currentAccountSize,
                    industryType: currentIndustryType,
                    lifeStage: currentLifeStage,
                    helpWith: currentHelpWith,
                    currentOffSet: 0,
                    search: textSearch,
                },
            };
            await getResults(queryBody);
        }
    }

    async function runLocationSearch() {
        if (!lat && !lon) {
            setLoading(true);
            navigator.geolocation.getCurrentPosition(async (position) => {
                setLat(position.coords.latitude);
                setLon(position.coords.longitude);
                let queryBody = {
                    body: {
                        state: currentState,
                        //accountSize: currentAccountSize,
                        industryType: currentIndustryType,
                        helpWith: currentHelpWith,
                        lifeStage: currentLifeStage,
                        currentOffSet: 0,
                        search: textSearch,
                        position: {
                            lat: position.coords.latitude,
                            lon: position.coords.longitude,
                        },
                    },
                };
                await getResults(queryBody);
            });
        } else {
            let queryBody = {
                body: {
                    state: currentState,
                    //accountSize: currentAccountSize,
                    industryType: currentIndustryType,
                    helpWith: currentHelpWith,
                    lifeStage: currentLifeStage,
                    currentOffSet: 0,
                    search: textSearch,
                    position: {
                        lat: lat,
                        lon: lon,
                    },
                },
            };
            await getResults(queryBody);
        }
    }

    const handleSelectedFilters = async () => {
        let queryBody = {
            body: {
                state: currentState,
                //accountSize: currentAccountSize,
                industryType: currentIndustryType,
                lifeStage: currentLifeStage,
                helpWith: currentHelpWith,
                currentOffSet: 0,
                search: textSearch,
                position: isLocationSort
                    ? {
                          lat: lat,
                          lon: lon,
                      }
                    : null,
            },
        };

        //RE ADD CURRENT ACCOUNT SIZE TO THIS IF STATEMENT
        if (!currentState && !currentLifeStage && !currentIndustryType && (!currentHelpWith || currentHelpWith.length === 0)) {
            setCurrentOffSet(0);
            setCurrentPage(0);
            setShowFiltersModal(false);
            return getResults(queryBody);
        }

        setShowFiltersModal(false);

        setCurrentPage(0);
        setCurrentOffSet(0);
        searchParams.delete('offset');
        searchParams.delete('page');
        if (currentState) searchParams.set('state', currentState);
        if (currentIndustryType) searchParams.set('industryType', currentIndustryType);
        if (currentLifeStage) searchParams.set('lifeStage', currentLifeStage);
        if (currentHelpWith && currentHelpWith.length > 0) searchParams.set('helpWith', currentHelpWith);
        if (textSearch) searchParams.set('search', textSearch);
        setSearchParams(searchParams);

        await getResults(queryBody);
    };

    async function handlePageAndOffSet(page, offset) {
        setCurrentPage(page);
        setCurrentOffSet(offset);
        searchParams.set('offset', offset);
        searchParams.set('page', page);
        if (currentState) searchParams.set('state', currentState);
        if (currentIndustryType) searchParams.set('industryType', currentIndustryType);
        if (currentLifeStage) searchParams.set('lifeStage', currentLifeStage);
        if (currentHelpWith && currentHelpWith.length > 0) searchParams.set('helpWith', currentHelpWith);
        if (textSearch) searchParams.set('search', textSearch);
        setSearchParams(searchParams);

        let queryBody = {
            body: {
                state: currentState,
                //accountSize: currentAccountSize,
                industryType: currentIndustryType,
                lifeStage: currentLifeStage,
                helpWith: currentHelpWith,
                currentOffSet: offset,
                search: textSearch,
                position: isLocationSort
                    ? {
                          lat: lat,
                          lon: lon,
                      }
                    : null,
            },
        };

        await getResults(queryBody);
    }

    async function getResults(queryBody) {
        try {
            setLoading(true);
            try {
                const results = await advisorProfileService.marketPlaceSearch(queryBody);
                setCurrentPageAdvisors(results.advisors);
                setTotalAdvisors(results.numAdvisors);
            } catch (e) {
                console.log(e);
            }
            setCurrentFiltersArray([queryBody.body.state, queryBody.body.industryType ?? 0, queryBody.body.lifeStage ?? 0, queryBody.body.helpWith]);
            hanldeMixpanelEvents(queryBody.body);
        } catch (err) {
            setError('Error loading, please try again later');
            setShowErrorBanner(true);
        } finally {
            setLoading(false);
        }
    }

    function hanldeMixpanelEvents(body) {
        if (body.state && body.state !== 0) {
            mixpanelTrack('Filter', 'State', body.state);
        }
        if (body.industryType && body.industryType !== 0) {
            const filterName = industryFilters.find((filter) => filter.id === body.industryType)?.value;
            mixpanelTrack('Filter', 'Industry', filterName);
        }
        if (body.lifeStage && body.lifeStage !== 0) {
            const filterName = lifeStageFilters.find((filter) => filter.id === body.lifeStage)?.value;
            mixpanelTrack('Filter', 'Life Stage', filterName);
        }
        if (body.helpWith && body.helpWith !== 0) {
            const filterName = helpWithFilters.find((filter) => filter.id === body.helpWith)?.value;
            mixpanelTrack('Filter', 'Help With', filterName);
        }
        if (body.search && body.search.length > 4) {
            mixpanelTrack('Filter', 'Search Text', body.search);
        }
        if (body.position?.lat && body.position?.lon) {
            mixpanelTrack('Filter', 'Position', `Lat: ${body.position.lat}, Lon: ${body.position.lon}`);
        }
    }

    const handleTextSearchChange = async (event) => {
        if (textSearch.length > 0 && event.target.value.length === 0) {
            setClearText(true);
        } else {
            setClearText(false);
        }
        setTextSearch(event.target.value);
        searchParams.set('search', event.target.value);
        setSearchParams(searchParams);
    };

    function handleClearText() {
        setClearText(true);
        setTextSearch('');
        searchParams.set('search', '');
        setSearchParams(searchParams);
    }

    const handleClearFilters = () => {
        setSearchParams({});
        setCurrentPage(0);
        setCurrentOffSet(0);

        let queryBody = {
            body: {
                state: stateSearch.toUpperCase(),
                accountSize: 0,
                industryType: 0,
                helpWith: 0,
                lifeStage: 0,
                currentOffSet: 0,
                search: textSearch,
                position: isLocationSort
                    ? {
                          lat: lat,
                          lon: lon,
                      }
                    : null,
            },
        };
        // setShowFiltersModal(false)
        getResults(queryBody);
    };

    const clearSpecficFilter = (filter, id) => {
        switch (filter) {
            case 'lifeStage':
                setCurrentLifeStage(0);
                searchParams.delete('lifeStage');
                break;
            case 'industryType':
                setCurrentIndustryType(0);
                searchParams.delete('industryType');
                break;
            case 'helpWith':
                const index = currentHelpWith.indexOf(id);
                currentHelpWith.splice(index, 1);
                setCurrentHelpWith(currentHelpWith);
                if (currentHelpWith.length === 0) {
                    searchParams.delete('helpWith');
                } else {
                    searchParams.set('helpWith', currentHelpWith);
                }
                break;
            default:
                return;
        }

        searchParams.delete('offset');
        searchParams.delete('page');
        setSearchParams(searchParams);
        setCurrentPage(0);
        setCurrentOffSet(0);

        let queryBody = {
            body: {
                state: currentState,
                //accountSize: currentAccountSize,
                industryType: filter === 'industryType' ? undefined : currentIndustryType,
                lifeStage: filter === 'lifeStage' ? undefined : currentLifeStage,
                helpWith: filter === 'helpWith' && currentHelpWith.length === 0 ? undefined : currentHelpWith,
                currentOffSet: 0,
                search: textSearch,
                position: isLocationSort
                    ? {
                          lat: lat,
                          lon: lon,
                      }
                    : null,
            },
        };

        getResults(queryBody);
    };

    return (
        <>
            {loading && (
                <div className='spinnerContainer'>
                    <ClipLoader color={'var(--adfi_blue500)'} loading={loading} size={100} />
                </div>
            )}
            <>

            {!loading && (
                    <SEO
                        title={`Financial Advisors in ${statesFull[stateIndex]}`}
                        description={`Browse financial advisors in ${statesFull[stateIndex]} on AdvisorFinder. Find a financial advisor who is ready to guide you through your financial journey, locally and conveniently.`}
                        name='AdvisorFinder'
                        type='website'
                        canonical={`https://advisorfinder.com/app/find-financial-advisor/state/${stateSearch}`}
                    />
                )}

                {showSearch && (
                    <div className={styles.mobileSearchContainer}>
                        <div className={styles.searchBarWrapper}>
                            <Search className={styles.searchIcon} size={16} color='rgba(45, 43, 65, 1)' />
                            <input
                                className={styles.searchBar}
                                placeholder={`Search Advisors`}
                                autoComplete='new-password'
                                type='text'
                                id='search'
                                value={textSearch}
                                onChange={(e) => handleTextSearchChange(e)}
                            />
                            <XCircle className={styles.cancelIcon} size={16} color='rgba(171, 170, 179, 1)' onClick={handleClearText} />
                        </div>
                    </div>
                )}
                <div className={classnames(styles.pageContainer, showFiltersModal ? styles.blockScrolling : '')}>
                    {showFiltersModal && (
                        <FormModal largeModal={true} noPadding={true} onClose={() => setShowFiltersModal(false)} show={showFiltersModal} title='Filters'>
                            <Filters
                                currentState={currentState}
                                setCurrentState={setCurrentState}
                                //currentAccountSize={currentAccountSize}
                                //setCurrentAccountSize={setCurrentAccountSize}
                                currentIndustryType={currentIndustryType}
                                setCurrentIndustryType={setCurrentIndustryType}
                                currentLifeStage={currentLifeStage}
                                setCurrentLifeStage={setCurrentLifeStage}
                                currentHelpWith={currentHelpWith}
                                setCurrentHelpWith={setCurrentHelpWith}
                                handleSelectedFilters={handleSelectedFilters}
                                handleClearFilters={handleClearFilters}
                                helpWithFilters={helpWithFilters}
                                industryFilters={industryFilters}
                                lifeStageFilters={lifeStageFilters}
                                hideStates={true}
                            />
                        </FormModal>
                    )}
                    <div className={styles.contentContainer}>
                        <div className={styles.dashboardContainer}>
                            <div className={styles.dashboard}>
                                <Grid size={20} color='var(--adfi_blue400)' />
                                <h1>Financial Advisors in {statesFull[stateIndex]}</h1>
                            </div>
                            <div className={styles.searchContainer}>
                                <Button color='gradient-button' onClick={() => setShowFiltersModal(!showSearch)}>
                                    Filters <ChevronDown />
                                </Button>
                                <div className={styles.searchBarWrapper}>
                                    <Search className={styles.searchIcon} size={16} color='rgba(45, 43, 65, 1)' />
                                    <input
                                        className={styles.searchBar}
                                        placeholder={`Search Advisors`}
                                        name='password'
                                        autoComplete='new-password'
                                        id='search'
                                        value={textSearch}
                                        onChange={(e) => handleTextSearchChange(e)}
                                    />
                                    <XCircle className={styles.cancelIcon} size={16} color='rgba(171, 170, 179, 1)' id='clearButton' tabIndex='0' onClick={handleClearText} />
                                </div>
                            </div>
                            <div className={styles.mobileDashboard}>
                                <Button color='grey-button white' onClick={() => setShowSearch(!showSearch)}>
                                    <Search />
                                </Button>
                                <Button color='grey-button white' onClick={() => setShowFiltersModal(true)}>
                                    <Filter />
                                </Button>
                            </div>
                        </div>
                        <div className={styles.greyLineDivider} />
                        <CurrentFilters
                            currentFiltersArray={currentFiltersArray}
                            clearSpecficFilter={clearSpecficFilter}
                            helpWithFilters={helpWithFilters}
                            industryFilters={industryFilters}
                            lifeStageFilters={lifeStageFilters}
                        />
                        <div className={styles.gridContainer}>
                            {currentPageAdvisors?.length !== 0 && (
                                <div className={styles.advisorsContainer}>
                                    <MarketplaceAdvisors
                                        isLocationSort={isLocationSort}
                                        handleLocationSortChange={handleLocationSortChange}
                                        advisorData={currentPageAdvisors}
                                        count={totalAdvisors}
                                        handlePageAndOffSet={handlePageAndOffSet}
                                        currentPage={currentPage}
                                        currentOffSet={currentOffSet}
                                        showFiltersModal={showFiltersModal}
                                    />
                                </div>
                            )}
                        </div>
                        {currentPageAdvisors?.length === 0 && <NoResults />}
                    </div>
                    {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{error}</ErrorBanner>}
                </div>
                <GlobalFooter background={true} />
            </>
        </>
    );
}

export default StateSearchPage;
