import React, { useState, useEffect } from "react";
import styles from "./JobHistoryAddForm.module.scss";
import { Check, Trash2 } from 'react-feather';
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import { v4 as uuidv4 } from 'uuid';

import "react-datepicker/dist/react-datepicker.css";
import ErrorBanner from "../../ErrorBanner/ErrorBanner";


export default function JobHistoryAddForm(props) {

    const data = props.data ? props.data : [];


    const [selectedJob, setSelectedJob] = useState(false);
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [startYear, setStartYear] = useState('');
    const [endYear, setEndYear] = useState('');


    const [editJob, setEditJob] = useState(false);

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);

    const firmAdminAuth = props.firmAdminAuth;

    useEffect(() => {
        if (props.selectedJob) {
            setSelectedJob(true)
            setCompany(props.selectedJob.company);
            setPosition(props.selectedJob.position);
            setStartYear(props.selectedJob.startYear);
            setEndYear(props.selectedJob.endYear);
            setEditJob(true)
        } else {
            setSelectedJob(false)
            setCompany('');
            setPosition('');
            setStartYear('');
            setEndYear('');
            setEditJob(false)
        }

    }, [props.selectedJob])

    function onChangeStart(e) {
        console.log(e.target.value)
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            if (e.target.value === '' || parseInt(e.target.value) <= new Date().getFullYear())
                setStartYear(e.target.value)
        }
    }

    function onChangeEnd(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            if (e.target.value === '' || parseInt(e.target.value) <= new Date().getFullYear())
            setEndYear(e.target.value)
        }
    }



    function cancelButton() {
        setCompany('');
        setPosition('');
        setStartYear('');
        setEndYear('');
        props.onClose();
    }



    function checkDisabled() {
        if (company && position) {
            return false;
        } else {
            return true;
        }
    }

    async function onSubmit(isDelete) {

        setSubmitDisabled(true);
        setShowErrorBanner(false);

        const submitValue = {
            jobHistoryId: uuidv4(),
            position,
            company,
            startYear,
            endYear
        }

        let columnData = {};

        if (editJob) {
            let newData = [];
            if (isDelete) {
                newData = data.filter(el => el.jobHistoryId !== props.selectedJob.jobHistoryId);
            } else {
                newData = data.map((job) => {
                    if (job.jobHistoryId === props.selectedJob.jobHistoryId) {
                        return {
                            jobHistoryId: job.jobHistoryId,
                            position,
                            company,
                            startYear,
                            endYear
                        }
                    };
                    return job;
                });

            }
            newData.forEach((job) => {
                if(!job.jobHistoryId) {
                    job.jobHistoryId = uuidv4()
                }
            });
        props.setData(newData);

            columnData = [
                {
                    jobHistory: newData
                }
            ];
        } else {
            columnData = [
                {
                    jobHistory: [...data, submitValue]
                }
            ];
            props.setData([...data, submitValue])
        }


        const myInit = {
            body: {
                columnData
            }
        };

        try {
            await advisorProfileService.update(props.advisor.professionalId, myInit, firmAdminAuth);
            setSubmitDisabled(false);
            props.onClose();
        } catch (error) {
            setSubmitDisabled(false);
            setErrors(error.toString());
            setShowErrorBanner(true);
            console.log(error);
        }


    }

    return (
        <>
            <div className={styles.formContainer}>


                <div className={styles.inputForm}>
                    <label htmlFor="company">Company/Firm</label>
                    <input
                        className="input full"
                        placeholder="Please enter the company name where you previously worked"
                        type="text"
                        id="company"
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                    />
                </div>

                <div className={styles.inputForm}>
                    <label htmlFor="position">Position Held</label>
                    <input
                        className="input full"
                        type="text"
                        placeholder="Add job title"
                        id="position"
                        value={position}
                        onChange={e => setPosition(e.target.value)}
                    />
                </div>

                <div className={`${styles.inputForm}`}>


                    <div className="input-container half-container no-padding">
                        <div className={styles.halfInput}>
                            <label htmlFor="startYear">Start Year</label>
                            <input
                                className="input"
                                type="number"
                                id="startYear"
                                placeholder="Enter Year"
                                value={startYear}
                                onChange={onChangeStart}
                            />
                        </div>

                        <div className={styles.halfInput}>
                            <label htmlFor="endYear">End Year</label>
                            <input
                                className="input"
                                type="number"
                                id="endYear"
                                placeholder="Enter Year"
                                value={endYear}
                                onChange={onChangeEnd}
                            />
                        </div>

                    </div>
                </div>



            </div>
            {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}
            <div className="modal-footer">
                {editJob && <>
                    <Button className={styles.removeButton} color="clear-button" disabled={!selectedJob || submitDisabled} onClick={() => onSubmit(true)}><Trash2 size={20} />Delete</Button>
                    <div className={styles.modalButtons}>
                        <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>Cancel</Button>
                        <Button className="modal-cancel-button" color="gradient-button" disabled={(!selectedJob || submitDisabled)} onClick={() => onSubmit(false)}><Check size={20} />Confirm</Button>
                    </div>
                </>}
                {!editJob &&
                    <>
                        <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>Cancel</Button>
                        <Button className="modal-cancel-button" color="gradient-button" disabled={checkDisabled()} onClick={() => onSubmit(false)}><Check size={20} />Confirm</Button>
                    </>}
            </div>
        </>

    )
}
