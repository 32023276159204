import React, { useState, useEffect } from "react";
import styles from "./ServiceAddForm.module.scss";
import { Search, Check, XCircle, Trash2 } from 'react-feather';
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import { getUrl } from "aws-amplify/storage"
import ErrorBanner from "../../ErrorBanner/ErrorBanner";

export default function ServiceAddForm(props) {

    const data = props.data ? props.data : [];
    const setData = props.setData;
    const optionsList = props.optionsList;
    const isService = props.isService;
    const [searchText, setSearchText] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([...optionsList]);
    const [selectedService, setSelectedService] = useState(props.selectedService);
    const [editService, setEditService] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [optionsIsOpen, setOptionsIsOpen] = useState(false);
    const [imgURL, setImgURL] = useState('');

    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);

    const firmAdminAuth = props.firmAdminAuth;

    useEffect(() => {
        setFilteredOptions(optionsList);
    }, [optionsList])

    useEffect(async () => {
        setSelectedService(props.selectedService);

        if (data.includes(props.selectedService)) {
            setEditService(true)
        } else {
            setEditService(false)
        }
        if (props.selectedService) {
            const id = props.selectedService.id;
            const serviceName = props.selectedService.value.trim().replaceAll(/[^a-zA-Z0-9 -_]/g, '').replaceAll(' ', '_');
            try {
                let url = ''
                if (isService) {
                    url = (await getUrl({key :`services/${id}_${serviceName}.svg`})).url.href;
                } else {
                    url = (await getUrl({key: `credentials/${id}_${serviceName}.png`})).url.href;
                }
                setImgURL(url);
            } catch (e) {
                console.error(e);
            }
        }
    }, [props.selectedService])

    function handleClickAway(e) {
        if (!e.relatedTarget) {
            setOptionsIsOpen(false);
        } else if (e.relatedTarget.id !== 'dropDownContainer') {
            setOptionsIsOpen(false);
        }
    }

    function handleChange(value) {
        setSearchText(value);
        filterOptions(value);
    }

    function cancelButton() {
        props.onClose();
        setOptionsIsOpen(false);
    }

    function filterOptions(value) {
        let tempData = []
        let tempFilterOptions = filteredOptions;
        tempData = tempFilterOptions.filter((filterData) => {
            const found = data.some(el => el.value === filterData.value);
            if (found) {
                return false;
            } else if (filterData.value.toLowerCase().includes(value.toLowerCase())) {
                return filterData;
            }
            return false;

        });
        return tempData;
    }

    async function onSubmit(submitValue) {
        setSubmitDisabled(true);
        setShowErrorBanner(false);
        let columnData;
        if (submitValue[submitValue.length - 1]) submitValue[submitValue.length - 1].sortingPriority = submitValue.length - 1;
        if (isService) {

            columnData = [
                {
                    services: submitValue
                }
            ];
        } else {
            columnData = [
                {
                    credentials: submitValue
                }
            ];
        }

        const myInit = {
            body: {
                columnData
            }
        };

        setOptionsIsOpen(false);
        try {
            await advisorProfileService.update(props.advisor.professionalId, myInit, firmAdminAuth);
            setData(submitValue);
            setSubmitDisabled(false);
            await props.updateCompletenessScore();
            props.onClose();
        } catch (error) {
            setSubmitDisabled(false);
            setErrors(error.toString());
            setShowErrorBanner(true);
            console.log(error);
        }

    }

    function removeData() {
        onSubmit(data.filter(el => el.value !== selectedService.value));

    }



    return (
        <>
            {filteredOptions && <div className={styles.formContainer}>
                {!selectedService &&
                    <div className={styles.searchBarWrapper}>
                        <Search className={styles.searchIcon} size={16} color="rgba(45, 43, 65, 1)" />
                        <input className={styles.searchBar} placeholder={isService ? `Search for provided services` : `Search for credentials and designations`} type="text" value={searchText}
                            onChange={e => handleChange(e.target.value)} onFocus={() => setOptionsIsOpen(true)}
                            onBlur={(e) => handleClickAway(e)} />
                        <XCircle className={styles.cancelIcon} size={16} color="rgba(171, 170, 179, 1)" onClick={() => setSearchText('')} />
                        {/* change to be when input is focused */}
                        {(optionsIsOpen || searchText.length > 0) && <div className={styles.dropDownWrapper} id="dropDownContainer" tabIndex="0">
                            {filterOptions(searchText).map((option, index) => (
                                <div key={index} className={styles.option} onClick={() => props.setSelectedService(option)}>{option.value}</div>
                            ))}

                        </div>}
                    </div>
                }

                {selectedService && <div className={styles.selectedServiceContainer}>
                    <span>{selectedService.value}</span>{!editService && <XCircle className={styles.clearIcon} size={16} color="rgba(171, 170, 179, 1)" onClick={() => setSelectedService(null)}></XCircle>}
                </div>}


                {selectedService && <div className={styles.sectionWrapper}>
                    <div className={styles.serviceImg}><img loading="lazy" src={imgURL} alt="service_img"></img></div> <span>{selectedService.description}</span>
                </div>}

            </div>
            }
            {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}
            <div className="modal-footer">
                {editService && <>
                    <Button className={styles.removeButton} color="clear-button" disabled={!selectedService || submitDisabled} onClick={() => removeData()}><Trash2 size={20} />Delete</Button>
                    <div className={styles.modalButtons}>
                        <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>Cancel</Button>
                        <Button className="modal-cancel-button" color="gradient-button" disabled={true}><Check size={20} />Confirm</Button>
                    </div>
                </>}
                {!editService && <>
                    <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>Cancel</Button>

                    <Button className="modal-cancel-button" color="gradient-button" disabled={(!selectedService || submitDisabled)} onClick={() => onSubmit([...data, selectedService])}><Check size={20} />Confirm</Button>
                </>
                }
            </div>
        </>

    )
}
