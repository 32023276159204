export default function getYears() {
    let years = []
    let i = 1960
    const currYear = new Date().getFullYear()

    for (i; i <= currYear; i++) {
        years.push(i)
    }

    return years;
}