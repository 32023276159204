
import styles from "./Disclosure.module.scss";


export default function RSNIPDisclosureText(props) {

  

    return (
        <>
            <div className={styles.rsnipTextArea}>
            <span>Investment and Insurance Products are:</span>
            <ul>
                <li>Not Insured by the FDIC or Any Federal Government Agency</li>
                <li>Not a Deposit or Other Obligation of, or Guaranteed by, the Bank of Any Bank Affiliate</li>
                <li>Subject to Investment Risks, Including Possible Loss of the Principal Amount Invested</li>
            </ul>
            </div>
        </>
    )
}