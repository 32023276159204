import React from 'react'
import {ArrowRight} from 'react-feather'
import styles from './Marketplace.module.scss'

function NoResults() {
    
    return (
        <div className={styles.noResultsContainer}>  
            <div className={styles.verticalOffsetMedium}><h1>No advisors found</h1></div>
            <img loading="lazy" src='/app/noresults.svg' alt ='' className={styles.verticalOffsetMedium}/>
            <div className={styles.verticalOffsetMedium}><h2>We advise to:</h2></div>
            <div className={styles.noResultsBulletContainer}>
                <span className={styles.noResultsBullet}>
                    <ArrowRight className={styles.noResultsBulletArrow}/>
                    <span>Confirm the spelling of search terms and try different keywords related to your financial needs.</span>
                </span>
                <span className={styles.noResultsBullet}>
                    <ArrowRight className={styles.noResultsBulletArrow}/>
                    <span>Broaden your search criteria to include a wider range of variables or remove a filter from above.</span>
                </span>
                <span className={styles.noResultsBullet}>
                    <ArrowRight className={styles.noResultsBulletArrow}/>
                    <span>Explore our resources to learn more about what advisor might be the best fit for you.</span>
                </span>
            </div>
        </div>
    )
}

export default NoResults;