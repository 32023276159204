import React from "react";
import styles from "./PricingInfo.module.scss";
import { CheckCircle } from 'react-feather';


export default function PricingInfo(props) {

    const data = props.data;


    return (
        <>
            <div className={styles.formContainer}>
                {data.map((pricingModel, index) => (
                    <div key={index} className={styles.pricingModelContainer}>
                        <div className={styles.checkCircle}><CheckCircle size={20} color={"var(--adfi_white)"}></CheckCircle></div>
                        <div className={styles.pricingTextContainer}>
                            <div className={styles.pricingTextValue}>{pricingModel.value}</div>
                            <div className={styles.pricingTextDescription}>{pricingModel.description}</div>
                        </div>
                    </div>
                ))}

            </div>
        </>
    )
}
