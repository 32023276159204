import React, { useState, useEffect } from "react";
import { getUrl } from "aws-amplify/storage"
import styles from "./ServicesProvided.module.scss";
import { ChevronUp, Plus, ChevronLeft, ChevronRight, ArrowRight, CheckCircle } from 'react-feather';
import FormModal from "../../Modals/FormModal";
import ServiceDetailsForm from "../../Forms/ServiceDetailsForm/ServiceDetailsForm";
import { ServiceCard } from "./ServiceCard";
import { SortableList } from "../SotableList/SortableList";
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import useMixPanelEvent from "../../../Utils/MixPanel";
import ContactAdvisorForm from '../../Forms/ContactAdvisorForm/ContactAdvisorForm';

const classnames = require('classnames');


export default function ServicesProvided(props) {

    const mixpanelTrack = useMixPanelEvent();

    const editMode = props.editMode;
    const isDesktop = props.isDesktop;
    const data = props.data;
    const professionalId = props.professionalId

    const [showMore, setShowMore] = useState(true);
    const [displayServices, setDisplayServices] = useState(data.slice(0, 4))
    const [startSlice, setStartSlice] = useState(0);
    const [endSlice, setEndSlice] = useState(4);
    const [disableNextButton, setDisableNextButton] = useState(false);
    const [disablePreviousButton, setDisablePreviousButton] = useState(true);
    const [showImg, setShowImg] = useState(Array(data.length).fill(true));
    const [serviceImgArray, setServiceImgArray] = useState(Array(data.length).fill(''))
    const [showServiceDetails, setShowServiceDetails] = useState(false);
    const [selectedService, setSelectedService] = useState({});
    const [services, setServices] = useState(data);
    const [showUpdateButton, setShowUpdateButton] = useState(false);
    const [showContactAdvisorModal, setShowContactAdvisorModal] = useState(false);

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const firmAdminAuth = props.firmAdminAuth;

    useEffect(async () => {
        setServices(props.data);
        await getServiceImg(props.data);
    }, [props.data]);

    useEffect(() => {
        services.forEach((item, index) => {
            if (item.sortingPriority !== index) {
                item.sortingPriority = index;
                setShowUpdateButton(true);
            }
        });
    }, [services])

    useEffect(async () => {
        setDisplayServices(data.slice(startSlice, endSlice))
        if (endSlice >= data.length) {
            setDisableNextButton(true);
        } else {
            setDisableNextButton(false);
        }

        if (startSlice >= 4) {
            setDisablePreviousButton(false)
        } else {
            setDisablePreviousButton(true);
        }

    }, [startSlice, endSlice]);

    useEffect(() => {
        if (editMode) setShowMore(true)
    }, [editMode])


    function nextPage() {
        if (disableNextButton) {
            return;
        }
        if (endSlice === data.length) {
            setDisableNextButton(true);
        } else {
            setDisableNextButton(false);
        }

        if (data.length < (endSlice + 4)) {
            setEndSlice(data.length);
        } else {
            setEndSlice(endSlice + 4);
        }
        setStartSlice(startSlice + 4);

    }

    function previousPage() {
        if (disablePreviousButton) {
            return;
        }
        if (endSlice === data.length) {
            setDisableNextButton(true);
        } else {
            setDisableNextButton(false);
        }

        if (startSlice >= 4) {
            setDisablePreviousButton(false)
        } else {
            setDisablePreviousButton(true);
        }

        setEndSlice(startSlice);
        setStartSlice(startSlice - 4);

    }


    async function updateOrder() {
        await onSubmit();
    }

    async function onSubmit() {
        setSubmitDisabled(true);
        let columnData = [
            {
                services: services
            }
        ];

        const myInit = {
            body: {
                columnData
            }
        };

        try {
            await advisorProfileService.update(professionalId, myInit, firmAdminAuth);
            props.setData(services);
            setSubmitSuccess(true)
            setShowUpdateButton(false);
        } catch (error) {
            console.log(error);
        } finally {
            setSubmitDisabled(false);
        }

    }

    function displayDescription(description) {
        if (!description) {
            return '';
        }
        else if (description.length <= 76) {
            return description
        } else {
            return `${description.substring(0, 76)}...`
        }
    }

    function handleClickService(service) {
        mixpanelTrack('Services click to expand', 'Service', service.value);
        setSelectedService(service + startSlice);
        setShowServiceDetails(true);
    }


    async function getServiceImg(data) {
        const promises = await data.map(async (value) => {
            const id = value.id;
            const serviceName = value.value.trim().replaceAll(/[^a-zA-Z0-9 -_]/g, '').replaceAll(' ', '_');
            let url = '';
            try {
                url = (await getUrl({key: `services/${id}_${serviceName}.svg`})).url.href
                return { id, url };
            } catch (e) {
                console.error(e);
            }
        })

        let results = await Promise.all(promises);
        let tempArr = [...serviceImgArray]
        results.forEach((item) => {
            const index = data.findIndex(x => x.id === item.id); //find the index of data
            tempArr[index] = item.url; // set the url
        })

        setServiceImgArray(tempArr);
        return results;
    }

    function showServiceImg(id) {
        const index = data.findIndex(x => x.id === id);
        return serviceImgArray[index];
    }


    return (
        <>
            {showServiceDetails && <FormModal onClose={() => setShowServiceDetails(false)} show={showServiceDetails} noPadding={true}>
                <ServiceDetailsForm serviceIndex={selectedService} serviceArray={props.data} setSelectedService={setSelectedService} isService={true} setShowServiceDetails={setShowServiceDetails} setShowContactAdvisorModal={setShowContactAdvisorModal}></ServiceDetailsForm>
            </FormModal>}
            {showContactAdvisorModal && (
                <FormModal onClose={() => setShowContactAdvisorModal(false)} show={showContactAdvisorModal} noPadding={true}>
                    <ContactAdvisorForm advisor={props.advisor} setShowContactAdvisorModal={setShowContactAdvisorModal}></ContactAdvisorForm>
                </FormModal>
            )}
            {editMode && <div className={styles.servicesContainer_edit}>
                <div className={styles.servicesHeader_edit}><ChevronUp className={classnames(styles.showMoreArrow, showMore && styles.open)} onClick={() => setShowMore(!showMore)} size={20} color="rgba(45, 43, 65, 1)" /><h2>Services Provided</h2><div className={styles.countNumber}>{data.length}</div>
                    {editMode && showUpdateButton && <div><Button className={styles.submitOrderButton} color="clear-button" disabled={submitDisabled} onClick={() => updateOrder()}>Update Order</Button>
                    </div>}
                    {submitSuccess && <div><CheckCircle size={16} color="var(--adfi_green500)" /></div>}
                    {editMode && <div className={styles.addIcons} onClick={props.onClick}><Plus size={16} color="rgba(129, 128, 141, 1)" /></div>}
                </div>
                <div className={classnames(!showMore && styles.clamp)}>
                    <SortableList
                        items={services}
                        onChange={setServices}
                        renderItem={(service) => (
                            <SortableList.Item id={service.id}>
                                <ServiceCard service={service} showServiceImg={showServiceImg} displayDescription={displayDescription} onEdit={props.onEdit}><SortableList.DragHandle className={styles.dragHandle} /></ServiceCard>
                            </SortableList.Item>
                        )}
                    />

                </div>
            </div>}

            {!editMode && <div className={styles.servicesContainer}>
                <div className={styles.servicesHeader}>
                    <div className={styles.headerTextContainer}>
                        <h2 className={styles.headerText}>Services Provided</h2>
                        <span className={styles.headerSubText}>How I help my clients</span>
                    </div>
                    <div className={styles.headerButtonContainer}>
                        <div className={styles.servicesExpander}>
                            {!disablePreviousButton && <ChevronLeft
                                size={20}
                                color="var(--adfi_blue300)"
                                className={styles.headerButton}
                                onClick={() => previousPage()}
                            />}
                            {!disableNextButton && <ChevronRight
                                size={20}
                                color="var(--adfi_blue300)"
                                className={styles.headerButton}
                                onClick={() => nextPage()}
                            />}
                        </div>
                    </div>
                </div>
                {isDesktop && <div className={styles.dataContainer}>
                    {displayServices.map((service, index) => (                        
                        <div className={styles.service} key={index} onClick={() => handleClickService(index)}>
                            <h3 className={styles.serviceName}>{service.value}</h3>
                            {showImg[index] && <div className={styles.serviceImg}><img loading="lazy" src={showServiceImg(service.id)} alt="service_img"></img></div>}
                            {!showImg[index] && <span className={styles.serviceDescription}>{displayDescription(service.description)}</span>}
                            <span className={styles.moreButton}>More<ArrowRight size={16} color="var(--adfi_blue500)" /></span>
                        </div>
                    ))}
                </div>}
                {!isDesktop && <div className={styles.dataContainer}>
                    {displayServices.map((service, index) => (
                        <div className={styles.service_edit} key={index} onClick={() => handleClickService(index)}>
                            <div className={styles.serviceImg}><img loading="lazy" src={showServiceImg(service.id)} alt="service_img"></img></div>
                            <div className={styles.serviceText_edit}>
                                <h3 className={styles.serviceName}>{service.value}</h3>
                                <span className={styles.serviceDescription}>{displayDescription(service.description)}</span>
                            </div>
                        </div>
                    ))}
                </div>}
            </div>}
        </>
    )
}