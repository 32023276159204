import React, { useState, useEffect } from 'react';
import { Users, Map } from 'react-feather';
import styles from "./MarketplaceAdvisors.module.scss";
import MarketplaceAdvisorCard from '../MarketplaceAdvisorCard/MarketplaceAdvisorCard'
import ReactPaginate from 'react-paginate';
import './pagination.css'

function MarketplaceAdvisors(props) {

    const advisorData = props.advisorData
    const [pageCount, setPageCount] = useState(0);
    const [isDesktop, setDesktop] = useState(window.innerWidth > 1040);
    const itemsPerPage = 8;
    const isLocationSort = props.isLocationSort;
    // const setIsLocationSort = props.setIsLocationSort;
    const handleLocationSortChange = props.handleLocationSortChange

    
    useEffect(() => {
        setPageCount(Math.ceil(props.count / itemsPerPage));
    }, [props.currentOffSet, itemsPerPage, advisorData, props.count]);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1040);
    };

    useEffect(async () => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    }, []);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % props.count;

        //Send changed page and offset back to parent
        props.handlePageAndOffSet(event.selected, newOffset)
    };

    return (
        <>
            <div className={styles.advisorsHeaderContainer}>
                {/* <div className={styles.numberOfProfessionals}>
                    <Users color="var(--adfi_blue400)" />
                    <h3>{props.count} Professionals</h3>
                </div> */}
                <div className={styles.advisorsSort}>
                    <div className={styles.distanceSort}><Map size={16} />Sort By Distance</div>
                    <label className={styles.optionRow}>
                        <div className={styles.distanceSortCheck} style={{ cursor: "pointer" }} > <input type="checkbox"
                            value={isLocationSort}
                            checked={isLocationSort}
                            onChange={(e) => handleLocationSortChange(e.target.checked)} />
                            <span className={styles.checkmark}></span></div>
                    </label>

                </div>

            </div>
            {
                advisorData &&
                <div className={`${styles.advisorCardsContainer} ${(props.showFiltersModal && !isDesktop) ? styles.blockScrolling : ''}`}>
                    {advisorData.map((singleAdvisor, index) => (
                        <MarketplaceAdvisorCard key={index} data={singleAdvisor} />
                    ))}
                </div>
            }
            {pageCount >= 0 && <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                forcePage={parseInt(props.currentPage)}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
                containerClassName='pagination'
                pageLinkClassName='page-num'
                previousLinkClassName='page-num'
                nextLinkClassName='page-num'
                activeClassName='active'
            />}
        </>
    )
}

export default MarketplaceAdvisors;
