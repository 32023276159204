import "./FormModal.scss";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { X } from 'react-feather';

function FormModal(props) {

    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);


    return ReactDOM.createPortal(
        <div className={`modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
            <div className={`modal-content ${props.largeModal ? 'modal-content-large' : 'modal-content'} ${props.noPadding ? 'no-padding' : ''}`} onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <X size={20} color={'var(--adfi_dark200)'} className="modal-close-icon" onClick={props.onClose} />
                    {props.noPadding ? <h4 className="modal-title" style={{paddingTop: "20px", paddingLeft: "25px"}}>{props.title}</h4> : <h4 className="modal-title">{props.title}</h4>}
                </div>
                <div className="modal-body">
                    {React.cloneElement(props.children, { onClose: props.onClose, setUser: props.setUser })}
                </div>
            </div>
        </div>, document.getElementById("root")
    );
}

export default FormModal;