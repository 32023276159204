import React from "react";
import styles from'./Assessment.module.scss'
import useIsScreenWidth from "../../hooks/useIsScreenWidth";
import {Check} from 'react-feather'

function AssessmentHeader(props) {

    const {currentStage} = props;

    const stageHeaders = ["Service", "Industry", "Stage", "Location", "Investment", "Others"];

    const incompleteStageCircle = () => {
        return(
            <div className={styles.outerCircle}>
                <div className={styles.middleCircle}>
                    <div className={styles.innerCircle}/>
                </div>
            </div>
        )
    }

    const completeStageCircle = () => {
        return (
            <div className={styles.outerCircle}>
                <Check className={styles.middleCircleIcon}/>
            </div>
        )
    }

    return(
        <div className={styles.headerContainer}>
            <span className={styles.logoContainer}>
                <a href="https://www.advisorfinder.com" rel="noopener">
                <img loading="lazy" src="/app/AdFi-logo-name.svg" alt="/AdFi-logo-name.svg" className="register-logo" />
                </a>
            </span>
            {useIsScreenWidth(1024) && (currentStage > 0) &&
                <div className={styles.stagesContainer}>
                    {stageHeaders.map((stage, i) => {
                        return(
                        <span className={styles.stage} key={i}>
                            {i + 1 < currentStage ? completeStageCircle() : incompleteStageCircle()}{stage}
                        </span>
                        )
                    })}
                </div>
            }
            <span className={styles.headerAlignmentContainer}/>
        </div>
    )

}

export default AssessmentHeader;
