import React, { useState, useEffect } from "react";
import styles from "./PhoneNumberForm.module.scss";
import { Check } from 'react-feather';
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import consumerProfileService from "../../../services/consumerProfile.service"
import ErrorBanner from '../../ErrorBanner/ErrorBanner';
import firmAdminService from "../../../services/firmAdmin.service";

export default function PhoneNumberForm(props) {

    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState(props.user.phoneNumber);
    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);


    function cancelButton() {
        props.onClose();
    }

    useEffect(() => {
        if (phoneNumber) {
            setSubmitDisabled(false);
        } else {
            setSubmitDisabled(true);
        }
    }, [phoneNumber]);

    async function onSubmit() {
        setSubmitDisabled(true)
        setShowErrorBanner(false);
        const columnData = [
            {
                phoneNumber
            }
        ];
        const myInit = {
            body: {
                columnData
            }
        };

        try {
            if (props.user.groups.includes('Advisor')) {
                await advisorProfileService.update(props.user.id, myInit);
            }
            else if (props.user.groups.includes('FirmAdmin')) {
                await firmAdminService.update(props.user.id, myInit);
            }
            else {
                await consumerProfileService.update(props.user.id, myInit);
            }
            props.setUser({
                ...props.user,
                phoneNumber
            });
            setSubmitDisabled(false)
            props.onClose();
        } catch (error) {
            console.log(error);
            setErrors(error.toString());
            setShowErrorBanner(true);
            setSubmitDisabled(false)
        }
    }




    return (
        <>
            <div className={styles.intoFormContainer}>
                <div className="input-container justify-left mobile-padding">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                        className="input full"
                        placeholder="Phone Number"
                        type="tel"
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)}
                    />
                </div>
                {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}
            </div>
            <div className="modal-footer">
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>Cancel</Button>
                <Button className="modal-cancel-button" color="gradient-button" onClick={onSubmit} disabled={submitDisabled}><Check size={20} />Confirm</Button>
            </div>
        </>
    )
}

