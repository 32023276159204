import React from "react";
import { useNavigate } from 'react-router';
import Button from "../../components/Button/Button";
import styles from './NotFoundPage.module.scss';
import { ArrowLeft } from 'react-feather';


function NotFoundPage() {
    const navigate = useNavigate();
    function goBack() {
        navigate(-1);
    }

    return (
        <div className={styles.notFoundPageContainer}>
            <img src='/app/AdFi-logo-name.svg' loading="lazy" alt="Adfi_logo" className={styles.adfiLogo} />
            <h1 className={styles.notFoundHeader}>
                Hey There!
            </h1>
            <p className={styles.notFoundMessage}>
                It looks like you've stumbled upon something that we can't find. Let's take you back to the home screen so we can get you back on track.
            </p>
            <Button className={styles.returnButton} size="large" color="gradient-button" onClick={() => goBack()}><ArrowLeft size={20} />Return</Button>
            <img src="https://adfi-images.s3.amazonaws.com/images/images/404.svg" loading="lazy" alt="404" />

        </div>
    )

}

export default NotFoundPage