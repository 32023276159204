import React from 'react';
import styles from "./GlobalFooter.module.scss";

function GlobalFooter(props) {
    

    return (
        <>
        <div className={props.background ? styles.backgroundGrey : ''}>
            <span style={{color: "#81808D", fontSize: "12px"}}>&copy;2024 AdvisorFinder LLC. All rights reserved</span>
            <div className={styles.linksContainer}>
                <a href="https://www.advisorfinder.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
                <span>|</span>
                <a href="https://www.advisorfinder.com/cookie-policy" target="_blank" rel="noreferrer">Cookie Policy</a>
                <span>|</span>
                <a href="https://www.advisorfinder.com/terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>
            </div>
            <div className={styles.disclosureTextContainer}>AdvisorFinder does not provide and does not intend to provide investment or legal advice through this Website. Information contained on this Website is for informational and educational purposes only. Third-party links and resources are provided for your convenience, and have not been independently verified by AdvisorFinder. AdvisorFinder is not responsible for the accuracy or viability of the information or services offered through third-parties. Inclusion of links to third-party content is not an endorsement by AdvisorFinder of such content or services. Use your discretion. AdvisorFinder does not manage client funds or hold custody of assets.</div>
        </div> 
        </>
    )
}

export default GlobalFooter;