import React, { useRef, useState, useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { uploadData } from 'aws-amplify/storage';
import styles from './ConsumerProfile.module.scss';
import Button from '../../components/Button/Button';
import FormModal from '../../components/Modals/FormModal';
import ProfPicForm from '../../components/Forms/ProfPicForm/ProfPicForm';
import IntroForm from '../../components/Forms/IntroForm/IntroForm';
import { User, Settings, Eye, Edit2, Briefcase, ChevronDown, PlusCircle, X, Check } from 'react-feather';
import consumerProfileService from '../../services/consumerProfile.service';
import { useNavigate } from 'react-router';
import useGetProfilePicture from '../../hooks/useGetProfilePicture';
import ClipLoader from 'react-spinners/ClipLoader';
import GlobalFooter from '../../components/GlobalFooter/GlobalFooter';

const classnames = require('classnames');

function ConsumerProfile() {
    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem('user'));
    const [showMore, setShowMore] = useState(false);
    const [showIntroModal, setShowIntroModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [consumer, setConsumer] = useState({});
    const [isDesktop, setDesktop] = useState(window.innerWidth > 1040);
    const [showProfPicModal, setShowProfPicModal] = useState(false);
    const [showLocationTextBox, setShowLocationTextBox] = useState(false);
    const [showJobTitleTextBox, setShowJobTitleTextBox] = useState(false);
    const [showCompanyNameTextBox, setShowCompanyNameTextBox] = useState(false);
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [profilePictureFile, setProfilePictureFile] = useState('');
    const profilePictureURL = useGetProfilePicture(user.userName);
    const [profilePicture, setProfilePicture] = useState('');

    const buttonRef = useRef(null);
    let fileInput = React.createRef();

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1040);
    };

    useEffect(async () => {
        // check to see if logged in user email = email param
        if (!user || !user.groups.includes('Consumer')) {
            navigate(`/404`, { replace: true }); // create access denied page
        } else {
            setEditMode(false);
            setLoading(true);
            await getConsumerInfo();
            setLoading(false);
        }
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    useEffect(async () => {
        if (profilePictureFile) {
            await onProcessFile();
        }
    }, [profilePictureFile]);

    useEffect(() => {
        setProfilePicture(profilePictureURL);
    }, [profilePictureURL]);

    function movePurle(mode) {
        setEditMode(mode);
        if (mode) {
            buttonRef.current.style.transform = `translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
        } else {
            buttonRef.current.style.transform = `translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`;
        }
    }

    function cancelJobTitleTextBox() {
        setJobTitle(consumer.jobTitle);
        setShowJobTitleTextBox(false);
    }

    function cancelCompanyNameTextBox() {
        setCompanyName(consumer.placeOfEmployment);
        setShowCompanyNameTextBox(false);
    }

    function cancelLocationTextBox() {
        setCity(consumer.city);
        setState(consumer.state);
        setShowLocationTextBox(false);
    }

    async function getConsumerInfo() {
        try {
            const consumerResponse = await consumerProfileService.getOne(user.email);
            setConsumer(consumerResponse);
            setCity(consumerResponse.city);
            setState(consumerResponse.state);
            setCompanyName(consumerResponse.placeOfEmployment);
            setJobTitle(consumerResponse.jobTitle);
            return consumerResponse;
        } catch (error) {
            console.log(error);
            navigate(`/404`, { replace: true });
        }
    }

    async function updateConsumerInfo(field, value, onSuccess) {
        let columnData = [{}];
        if (field === 'location') {
            const cityValue = {
                city: value[0],
            };
            const stateValue = {
                state: value[1],
            };
            columnData = [cityValue, stateValue];
        } else {
            columnData[0][field] = value;
        }

        const myInit = {
            body: {
                columnData,
            },
        };

        try {
            await consumerProfileService.update(consumer.consumerId, myInit);
            let tempObj = { ...consumer };
            tempObj[field] = value;
            setConsumer(tempObj);
            this.onSuccess();
        } catch (error) {
            console.log(error);
        }
    }

    async function onProcessFile() {
        let reader = new FileReader();
        try {
            reader.readAsDataURL(profilePictureFile);
        } catch (err) {
            console.log(err);
        }
        reader.onloadend = () => {
            setProfilePicture(reader.result);
        };

        const { username } = await getCurrentUser();
        try {
            uploadData({
                key: `profilePictures/${username}_profilePicture.png`,
                data: profilePictureFile,
                options: {
                    contentType: 'image/png',
                },
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            {loading && (
                <div className='spinnerContainer'>
                    <ClipLoader color={'var(--adfi_blue500)'} loading={loading} size={100} />
                </div>
            )}
            {Object.keys(consumer).length !== 0 && !loading && (
                <div className={`${styles.pageContainer} ${styles.sideNavMargin}`}>
                    <div className={styles.profileHeader}>
                        <User size={24} color={'var(--adfi_blue500)'} />
                        {isDesktop && <div className={styles.profileHeaderText}>My Profile</div>}
                        <div className={styles.toggleWrapper}>
                            <div className={styles.profileToggle}>
                                <div ref={buttonRef} className={styles.togglePurple}></div>
                                <div className={styles.toggleText} style={{ color: !editMode ? '#fff' : 'var(--adfi_blue500)' }} onClick={() => movePurle(false)}>
                                    {isDesktop ? <div>Preview</div> : <Eye size={20}></Eye>}
                                </div>
                                <div className={styles.toggleText} style={{ color: !editMode ? 'var(--adfi_blue500)' : '#fff' }} onClick={() => movePurle(true)}>
                                    {isDesktop ? <div>Edit View</div> : <Edit2 size={20}></Edit2>}
                                </div>
                            </div>
                        </div>
                        <div className={styles.rightSideButtons}>
                            <Button color='gradient-button' onClick={() => navigate('/account-settings')}>
                                <Settings size={20} />
                                {isDesktop && <>Settings</>}
                            </Button>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.profileContainer}>
                            {showProfPicModal && (
                                <FormModal onClose={() => setShowProfPicModal(false)} show={showProfPicModal} title='Profile Picture'>
                                    <ProfPicForm
                                        setProfilePictureFile={setProfilePictureFile}
                                        currentProfilePicture={profilePicture === '/app/images/profilePictureDefault.svg' ? '' : profilePicture}></ProfPicForm>
                                </FormModal>
                            )}
                            {showIntroModal && (
                                <FormModal onClose={() => setShowIntroModal(false)} show={showIntroModal} title={'Your Introduction'} setUser={setConsumer}>
                                    <IntroForm user={consumer}></IntroForm>
                                </FormModal>
                            )}

                            <div className={styles.profileHeadshotContainer}>
                                <div className={styles.headshotInfoContainer}>
                                    <a href='#'>
                                        <input type='file' onChange={onProcessFile} ref={fileInput} hidden={true} />
                                    </a>
                                    {profilePicture && (
                                        <img
                                            loading='lazy'
                                            onClick={() => (editMode ? setShowProfPicModal(true) : undefined)}
                                            className={classnames(
                                                styles.picturePreviewImg,
                                                editMode ? styles.cursorPointer : '',
                                                !(profilePicture === '/app/images/profilePictureDefault.svg') ? styles.gradientBorder : styles.pupleBorder
                                            )}
                                            src={profilePicture}
                                            alt='profile_picture'></img>
                                    )}
                                    <div className={styles.profileInfoContainer}>
                                        {/* <div className={styles.locationText}>{consumer.city}, {consumer.state}</div> */}

                                        {!consumer.city && editMode && !showLocationTextBox && (
                                            <div onClick={() => setShowLocationTextBox(true)} className={classnames(styles.addBio, styles.purpleBackground)}>
                                                <PlusCircle size={16} color='rgba(113, 52, 243, 1)'></PlusCircle>Add Info About Where You're From
                                            </div>
                                        )}
                                        {showLocationTextBox && editMode && (
                                            <div>
                                                {' '}
                                                <div className={styles.inlineInputContainer}>
                                                    <input className='input full' id='city' placeholder='City' type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                                                    <input className='input full' id='state' placeholder='State' type="text" value={state} onChange={(e) => setState(e.target.value)} />
                                                    <div style={{ display: 'flex', gap: '5px' }}>
                                                        <X color='rgba(113, 52, 243, 1)' size={16} onClick={() => cancelLocationTextBox()}></X>
                                                        <Check
                                                            color='rgba(113, 52, 243, 1)'
                                                            size={16}
                                                            onClick={() => updateConsumerInfo('location', [city, state], setShowLocationTextBox(false))}></Check>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {consumer.city && !showLocationTextBox && (
                                            <div className={styles.flexRow}>
                                                <div className={styles.locationText}>
                                                    {consumer.city}, {consumer.state}
                                                </div>
                                                {editMode && <Edit2 onClick={() => setShowLocationTextBox(true)} size={16} style={{ ...{ marginLeft: 5 }, ...{ cursor: 'pointer' } }} />}
                                            </div>
                                        )}

                                        <div className={styles.advisorNameText}>
                                            {consumer.firstName} {consumer.lastName}
                                        </div>

                                        {!consumer.jobTitle && editMode && !showJobTitleTextBox && (
                                            <div onClick={() => setShowJobTitleTextBox(true)} className={classnames(styles.addBio, styles.purpleBackground)}>
                                                <PlusCircle size={16} color='rgba(113, 52, 243, 1)'></PlusCircle>Add info about your work
                                            </div>
                                        )}
                                        {showJobTitleTextBox && editMode && (
                                            <div>
                                                {' '}
                                                <div className={styles.inlineInputContainer}>
                                                    <input className='input full' id='jobTitle' placeholder='Add info about your work' value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
                                                    <div style={{ display: 'flex', gap: '5px' }}>
                                                        <X color='rgba(113, 52, 243, 1)' size={16} onClick={() => cancelJobTitleTextBox()}></X>
                                                        <Check color='rgba(113, 52, 243, 1)' size={16} onClick={() => updateConsumerInfo('jobTitle', jobTitle, setShowJobTitleTextBox(false))}></Check>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {consumer.jobTitle && !showJobTitleTextBox && (
                                            <div className={styles.flexRow}>
                                                <div className={styles.jobTitle}>{consumer.jobTitle} at</div>
                                                {editMode && <Edit2 onClick={() => setShowJobTitleTextBox(true)} size={16} style={{ ...{ marginLeft: 5 }, ...{ cursor: 'pointer' } }} />}
                                            </div>
                                        )}

                                        {!consumer.placeOfEmployment && editMode && !showCompanyNameTextBox && (
                                            <div onClick={() => setShowCompanyNameTextBox(true)} className={classnames(styles.addBio, styles.purpleBackground)}>
                                                <PlusCircle size={16} color='rgba(113, 52, 243, 1)'></PlusCircle>Add company you work for
                                            </div>
                                        )}
                                        {showCompanyNameTextBox && editMode && (
                                            <div>
                                                {' '}
                                                <div className={styles.inlineInputContainer}>
                                                    <input
                                                        className='input full'
                                                        id='company'
                                                        placeholder='Add company you work for'
                                                        value={companyName}
                                                        onChange={(e) => setCompanyName(e.target.value)}
                                                    />
                                                    <div style={{ display: 'flex', gap: '5px' }}>
                                                        <X color='rgba(113, 52, 243, 1)' size={16} onClick={() => cancelCompanyNameTextBox()}></X>
                                                        <Check
                                                            color='rgba(113, 52, 243, 1)'
                                                            size={16}
                                                            onClick={() => updateConsumerInfo('placeOfEmployment', companyName, setShowCompanyNameTextBox(false))}></Check>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {consumer.placeOfEmployment && !showCompanyNameTextBox && (
                                            <div className={styles.flexRow}>
                                                <div className={styles.employerText}>
                                                    <Briefcase size={16} color='rgba(113, 52, 243, 1)'></Briefcase>
                                                    {consumer.placeOfEmployment}
                                                </div>
                                                {editMode && <Edit2 onClick={() => setShowCompanyNameTextBox(true)} size={16} style={{ ...{ marginLeft: 5 }, ...{ cursor: 'pointer' } }} />}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={styles.advisorIntroContainer}>
                                <div className={styles.advisorIntroTitle}>
                                    Allow Me to Introduce Myself
                                    {editMode && <Edit2 onClick={() => setShowIntroModal(true)} size={16} style={{ ...{ marginLeft: 16 }, ...{ cursor: 'pointer' } }} />}
                                </div>
                                {!consumer.bio && editMode && (
                                    <div className={styles.emptyBio}>
                                        <span>Text with recommended bio information</span>
                                        <div className={styles.addBio} onClick={() => setShowIntroModal(true)}>
                                            <PlusCircle size={16} color='rgba(113, 52, 243, 1)'></PlusCircle>Add your intro
                                        </div>
                                    </div>
                                )}
                                {consumer.bio && (
                                    <>
                                        <div className={classnames(styles.advisorIntroText, !showMore && styles.clamp)}>{consumer.bio}</div>
                                        <div className={styles.showMore} onClick={() => setShowMore(!showMore)}>
                                            <ChevronDown className={classnames(styles.showMoreArrow, showMore && styles.open)} size={16} />
                                            <div>{!showMore ? 'More' : 'Less'}</div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!loading && <GlobalFooter background={true} />}
        </>
    );
}

export default ConsumerProfile;
