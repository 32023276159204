import React from "react";
import styles from "./SpecialitiesExpertiseInfo.module.scss";
import { AlertCircle, Mail, ArrowLeft, ArrowRight } from 'react-feather';
import Button from '../../Button/Button';
import isNotLoggedInAdvisor from "../../../Utils/isNotLoggedInAdvisor";


export default function SpecialitiesExpertiseInfo(props) {

    
    const {currentIndex, setSelectedSpeciality} = props;
    const data = props.data[currentIndex];
    const arrayLength = props.data.length - 1;

    const handleBackScroll = () => {
        if (currentIndex === 0) {
            return setSelectedSpeciality(arrayLength)
        }

        if(currentIndex <= arrayLength) {
            return setSelectedSpeciality(currentIndex - 1)
        }
    }

    const handleForwardScroll = () => {
        if(currentIndex < arrayLength) {
            return setSelectedSpeciality(currentIndex + 1)
        }

        if (currentIndex === arrayLength) {
            return setSelectedSpeciality(0)
        }
    }

    const openContactModal = () => {
        props.setShowSpecialitiesModal(false)
        props.setShowContactAdvisorModal(true)
    }

    return (
        <>
            <div className={styles.formContainer}>
                <h1>{data.value}</h1>
                {data.whatItInvolves && <div className={styles.greenInfoContainer}>
                    <AlertCircle size={20} />
                    {data.whatItInvolves}
                </div>}
                {data.whoItsFor && <div className={styles.infoSection}>
                    <h3>Who It's For:</h3>
                    <p>{data.whoItsFor}</p>
                </div>}
                {data.whatYouShouldKnow && <div className={styles.infoSection}>
                    <h4>What You Should Know: </h4>
                    <p>{data.whatYouShouldKnow}</p>
                </div>}
            </div>

            <div className={styles.modalFooter}>
                <span className={styles.footerScrollButtons}>
                    <Button color='clear-button white' onClick={() => handleBackScroll()}>
                        <ArrowLeft size={20} />
                    </Button>
                    <Button color='clear-button white' onClick={() => handleForwardScroll()}>
                        <ArrowRight size={20} />
                    </Button>
                </span>
                {isNotLoggedInAdvisor() && <Button color='gradient-button' onClick={() => openContactModal()}>
                    Contact
                    <Mail size={20} />
                </Button>}
            </div>
        </>
    )
}