import validator from 'validator';

function validateAdditionalDetails(values) {
    let errors = {};

    if (values.AUM) {
        if (!validator.isNumeric(values.AUM)) {
            errors.AUM = 'Enter a valid AUM without special characters'
        }
    }

    if (values.averageAccountSize) { 
        if (!validator.isNumeric(values.averageAccountSize)) {
            errors.averageAccountSize = 'Enter a valid average account size without special characters'
        }
    }

    if(values.clientNumber) {
        if (!validator.isNumeric(values.clientNumber)) {
            errors.clientNumber = 'Enter a valid number without special characters'
        }
    }

    return errors;
}

export default validateAdditionalDetails;