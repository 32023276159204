import React, { useEffect, useState, useContext } from 'react';
import Button from '../../components/Button/Button';
import { ReviewsCarousel } from '../../components/ReviewsCarousel/ReviewsCarousel';
import { ArrowRight, Upload } from 'react-feather';
import FormModal from '../../components/Modals/FormModal';
import ProfPicForm from '../../components/Forms/ProfPicForm/ProfPicForm';
import validateConsumerDetails from '../../Utils/validateConsumerDetails';
import { useParams } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';
import { uploadData } from 'aws-amplify/storage';

import { useNavigate } from 'react-router';
import { UserContext } from '../../Utils/UserContext';
import consumerProfileService from '../../services/consumerProfile.service';
import { states } from '../../constants/states';
import ErrorBanner from '../../components/ErrorBanner/ErrorBanner';
import ClipLoader from 'react-spinners/ClipLoader';

function ConsumerDetails(props) {
    const navigate = useNavigate();

    let { id, email } = useParams();

    const { user, setUser } = useContext(UserContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [employment, setEmployment] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [profilePicture, setProfilePicture] = useState('/app/images/profilePictureDefault.svg');
    const [profilePictureFile, setProfilePictureFile] = useState('');
    const [showProfPicModal, setShowProfPicModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [advisorProfileRedirect] = useState(JSON.parse(localStorage.getItem('advisorProfileRedirect')));

    useEffect(() => {
        if (email !== JSON.parse(localStorage.getItem('user')).email || id.toString() !== JSON.parse(localStorage.getItem('user')).id.toString()) {
            navigate(`/404`, { replace: true }); // create access denied page
        }
    }, [id, email]);

    useEffect(() => {
        if (firstName === '' || lastName === '') {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [firstName, lastName, employment, jobTitle]);

    async function handleSubmit(event) {
        event.preventDefault();
        setShowErrorBanner(false);
        setLoading(true);
        const validate = validateConsumerDetails({ firstName, lastName });

        if (Object.keys(validate).length === 0) {
            setErrors({});
            try {
                await addDetails();
            } catch (error) {
                setErrors(error.toString());
                setShowErrorBanner(true);
                throw new Error(error);
            } finally {
                setLoading(false);
            }
        } else {
            setErrors(validate);
            setLoading(false);
        }
    }

    async function addDetails() {
        let columnData = [
            {
                firstName,
            },
            {
                lastName,
            },
            {
                city,
            },
            {
                state,
            },
            {
                placeOfEmployment: employment,
            },
            {
                jobTitle,
            },
        ];
        const myInit = {
            body: {
                columnData,
            },
        };

        try {
            await consumerProfileService.update(id, myInit);
            const userObj = {
                ...user,
                firstName: firstName,
                lastName: lastName,
            };
            setUser(userObj);
            if (advisorProfileRedirect) {
                window.location = `${advisorProfileRedirect}`;
            }
            window.location = `/app/dashboard`;
        } catch (error) {
            throw new Error(error);
        }
    }

    useEffect(async () => {
        if (profilePictureFile) {
            await onProcessFile();
        }
    }, [profilePictureFile]);

    async function onProcessFile() {
        let reader = new FileReader();
        try {
            reader.readAsDataURL(profilePictureFile);
        } catch (err) {
            console.log(err);
        }
        reader.onloadend = () => {
            setProfilePicture(reader.result);
        };

        const { username } = await getCurrentUser();
        try {
            uploadData({
                key: `profilePictures/${username}_profilePicture.png`,
                data: profilePictureFile,
                options: {
                    contentType: 'image/png',
                },
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            {loading && (
                <div className='spinnerContainer'>
                    <ClipLoader color={'var(--adfi_blue500)'} loading={loading} size={100} />
                </div>
            )}
            <div className='page-container'>
                <div class='register-container left'>
                    <div className='logo-container'>
                        <a href='https://www.advisorfinder.com' rel='noopener'>
                            <img loading='lazy' src='/app/AdFi-logo-name.svg' alt='/AdFi-logo-name.svg' className='register-logo' />
                        </a>
                    </div>
                    <div className='form-container'>
                        <h1 className='register-header'>Does this look like you?</h1>
                        {showProfPicModal && (
                            <FormModal onClose={() => setShowProfPicModal(false)} show={showProfPicModal} title='Profile Picture'>
                                <ProfPicForm
                                    setProfilePictureFile={setProfilePictureFile}
                                    currentProfilePicture={profilePicture === '/app/images/profilePictureDefault.svg' ? '' : profilePicture}></ProfPicForm>
                            </FormModal>
                        )}
                        <img loading='lazy' className='picture-preview-img' src={profilePicture} alt='profile_picture'></img>
                        <div className='upload-image-container' onClick={() => setShowProfPicModal(true)}>
                            <Upload size={15} />
                            <p>Upload your look (optional)</p>
                        </div>
                        <form className='register-form' onSubmit={handleSubmit} style={{ width: 'fit-content' }}>
                            <div className='input-container'>
                                <input
                                    className='input full'
                                    placeholder='First Name'
                                    type="text"
                                    style={{ maxWidth: '400px', minWidth: '350px' }}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </div>
                            {errors.firstName && (
                                <p className='error-message' style={{ marginTop: '10px' }}>
                                    {errors.firstName}
                                </p>
                            )}
                            <div className='input-container'>
                                <input className='input full' type="text" placeholder='Last Name' style={{ maxWidth: '400px', minWidth: '350px' }} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                            {errors.lastName && (
                                <p className='error-message' style={{ marginTop: '10px' }}>
                                    {errors.lastName}
                                </p>
                            )}
                            <div className='input-container' style={{ gap: '5px' }}>
                                <input className='input' placeholder='City (optional)' type="text" style={{ maxWidth: '350px', minWidth: '300px' }} value={city} onChange={(e) => setCity(e.target.value)} />
                                <select className='input' value={state} onChange={(e) => setState(e.target.value)}>
                                    <option value=''></option>
                                    {states.map((key) => (
                                        <option value={key} key={key}>
                                            {key}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='input-container'>
                                <input
                                    className='input full'
                                    placeholder='Place of employment (optional)'
                                    style={{ maxWidth: '400px', minWidth: '350px' }}
                                    value={employment}
                                    onChange={(e) => setEmployment(e.target.value)}
                                />
                            </div>
                            <div className='input-container'>
                                <input
                                    className='input full'
                                    placeholder='Job Title (optional)'
                                    style={{ maxWidth: '400px', minWidth: '350px' }}
                                    value={jobTitle}
                                    onChange={(e) => setJobTitle(e.target.value)}
                                />
                            </div>
                            <div className='input-container'>
                                <Button color='gradient-button large' type='submit' disabled={disabled}>
                                    Looks Good
                                    <ArrowRight size={20} />
                                </Button>
                            </div>
                        </form>
                        {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}
                    </div>
                </div>
                <div class='register-container right'>
                    <ReviewsCarousel />
                </div>
            </div>
        </>
    );
}

export default ConsumerDetails;
