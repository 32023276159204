import React, { useState, useEffect } from 'react';
import styles from './ScheduleCallNoUserForm.module.scss';
import { ArrowRight } from 'react-feather';
import Button from '../../Button/Button';
import validateScheduleCall from '../../../Utils/validateScheduleCall';
import validateScheduleCallNoCalendly from '../../../Utils/validateScheduleCallNoCalendly';

export default function ScheduleCallNoUserForm(props) {
    const consumer = JSON.parse(localStorage.getItem('user'));
    const [fullName, setFullName] = useState(consumer ? `${consumer.firstName ?? ''} ${consumer.lastName ?? ''}` : '');
    const [email, setEmail] = useState(consumer ? consumer.email : '');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState({});
    const [isDisabled, setIsDisabled] = useState(true);
    const [textAreaContent, setTextAreaContent] = useState('');
    const [characterCount, setCharacterCount] = useState(0);

    const [scheduleTypeSelected, setScheduleTypeSelected] = useState('');
    const advisor = props.advisor;
    const [scheduleTypes, setScheduleTypes] = useState(filterScheduleTypes());

    const hasCalendly = advisor.calendlyUser && advisor.calendlyUrl.length > 0 ? true : false;

    useEffect(() => {
        if (fullName === '' || email === '' || (advisor.calendlyUser ? false : !scheduleTypeSelected)) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [fullName, email, scheduleTypeSelected, advisor.calendlyUser]);

    function filterScheduleTypes() {
        let types = [];
        if (advisor.email) {
            types = types.concat([
                { name: 'Contact Advisor via email', id: 'advisorEmail', value: false },
                { name: 'Receive call from Advisor', id: 'consumerPhoneNumber', value: false },
                { name: 'Receive email from Advisor', id: 'consumerEmail', value: false },
            ]);
        }
        if (advisor.phoneNumber) {
            types.push({ name: 'Call Advisor', id: 'advisorPhoneNumber', value: false });
        }
        return types;
    }

    function handleMeetingInfoChange(value) {
        setCharacterCount(value.length);
        if (value.length <= 999) {
            setTextAreaContent(value);
        } else {
            setIsDisabled(true);
        }
    }

    const handleToggle = (i) => {
        let tempState = [...scheduleTypes];
        for (let x = 0; x < tempState.length; x++) {
            tempState[x].value = false;
            if (x === i) {
                tempState[x].value = true;
                setScheduleTypeSelected(tempState[x].id);
            }
        }
        setScheduleTypes(tempState);
    };

    async function handleSubmit(event) {
        event.preventDefault();

        let validate;
        if (hasCalendly) {
            validate = validateScheduleCallNoCalendly({ fullName, email });
        } else {
            validate = validateScheduleCall({ fullName, email, phone, textAreaContent });
        }

        if (Object.keys(validate).length === 0) {
            setIsDisabled(false);
            setErrors('');
            await props.handleInfoInputComplete({ fullName: fullName, email: email, phone: phone, scheduleType: scheduleTypeSelected, meetingDetails: textAreaContent });
        } else {
            setErrors(validate);
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className={styles.scheduleSection}>
                    <h2 className={styles.verticalOffSet}>Request a Meeting with {advisor.firstName}</h2>
                    <div className={styles.verticalOffSet}>
                        <label>Please enter your Full Name</label>
                        <input
                            className={styles.input}
                            type="name"
                            id="name"
                            placeholder='ex: John Smith'
                            value={fullName}
                            onChange={e => setFullName(e.target.value)}
                        />
                        {errors.fullName && <p className='error-message'>{errors.fullName}</p>}
                    </div>
                    <div className={styles.verticalOffSet}>
                        <label>Please enter your email</label>
                        <input
                            className={styles.input}
                            type="email"
                            id="name"
                            value={email}
                            placeholder='ex: john@email.com'
                            onChange={e => setEmail(e.target.value)}
                        />
                        {errors.email && <p className='error-message'>{errors.email}</p>}
                    </div>

                    {!hasCalendly && (
                        <>
                            <div className={styles.verticalOffSet}>
                                <label>Please enter your phone number</label>
                                <input
                                    className={styles.input}
                                    type="tel"
                                    id="phone"
                                    value={phone}
                                    placeholder='ex: XXX-XXX-XXXX'
                                    onChange={e => setPhone(e.target.value)}
                                />
                                {errors.phone && <p className='error-message'>{errors.phone}</p>}
                            </div>

                            <label>How would you like to be contacted?</label>
                            {scheduleTypes?.map((type, i) => {
                                return (
                                    <div key={i} className={styles.contactType} onClick={(e) => handleToggle(i)}>
                                        <input type='checkbox' className={styles.inputHorizontalOffset} value={type.name} checked={type.value} onChange={(e) => handleToggle(i)} />
                                        {type.name}
                                    </div>
                                );
                            })}

                            <label>Please share anything that will help prepare for our meeting. (Optional)</label>
                            <textarea id='bioText' className={styles.infoTextArea} onChange={(e) => handleMeetingInfoChange(e.target.value)} value={textAreaContent} />
                            <p className={styles.characterCount}>{characterCount} / 1000</p>
                            {errors.textAreaContent && <p className='error-message'>{errors.textAreaContent}</p>}
                        </>
                    )}
                </div>

                <div className={styles.modalFooter}>
                    <span className={styles.footerText}>
                        By submitting, I agree to the{' '}
                        <a href='https://www.advisorfinder.com/terms-of-service' target='_blank' rel='noreferrer'>
                            Terms of Service
                        </a>{' '}
                    </span>
                    <Button className='modal-cancel-button' type='submit' color='gradient-button' disabled={isDisabled || props.submitDisabled}>
                        Continue
                        <ArrowRight size={20} />
                    </Button>
                </div>
            </form>
        </>
    );
}
