import { useEffect, useState } from "react";
import styles from "./CredentialForm.module.scss";
import { getUrl } from "aws-amplify/storage"
import { Info } from 'react-feather';


export default function ServiceDetailsForm(props) {
    const service = props.service


    const [imgURL, setImgURL] = useState('');

    useEffect(async () => {

        if (service.id) {
            const id = service.id;
            const serviceName = service.value.trim().replaceAll(/[^a-zA-Z0-9 -_]/g, '').replaceAll(' ', '_');

            try {
                let url = ''
                url = (await getUrl({ key: `credentials/${id}_${serviceName}.png`})).url.href;
                setImgURL(url);
            } catch (e) {
                console.error(e);
            }
        }

    }, [service])


    return (
        <>
            <div className={styles.formContainer}>
                <div className={styles.serviceImg}>
                    <img loading="lazy" src={imgURL} alt="service_img"></img>
                </div>
                <div className={styles.serviceName}>{service.value}</div>
                <div className={styles.quckFactContainer}>
                    <Info size={20} color={`var(--adfi_green500)`} /> <span>{service.quickFact}</span>
                </div>
                <div className={styles.detailsContainer}>
                    <span className={styles.descriptionHeader}>Description:</span>
                    <div>
                        {service.description}
                    </div>
                </div>
                <div className={styles.detailsContainer}>
                    <span className={styles.descriptionHeader}>Requirements:</span>
                    <div>
                        {service.requirements}
                    </div>
                </div>
            </div>

            {/* <div className={styles.modalFooter}>
                <span className={styles.footerScrollButtons}>
                    <Button color='clear-button white' onClick={() => handleBackScroll()}>
                        <ArrowLeft size={20} />
                    </Button>
                    <Button color='clear-button white' onClick={() => handleForwardScroll()}>
                        <ArrowRight size={20} />
                    </Button>
                </span>
                {isNotLoggedInAdvisor() && <Button color='gradient-button' onClick={() => openContactModal()}>
                    Contact
                    <Mail size={20} />
                </Button>}
            </div> */}
        </>
    )
}
