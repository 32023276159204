import React, { useState, useEffect, useRef } from 'react';
import styles from './AssessmentResults.module.scss';
import { ArrowRight, ArrowLeft } from 'react-feather';
import consumerProfileService from '../../services/consumerProfile.service';
import Button from '../../components/Button/Button';
import useMixPanelEvent from '../../Utils/MixPanel';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ClipLoader from 'react-spinners/ClipLoader';
import AssessmentCard from './AssessmentCard';
import { useNavigate } from 'react-router-dom';
import useIsScreenWidth from '../../hooks/useIsScreenWidth';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function AssessmentResults(props) {
    const mixpanelTrack = useMixPanelEvent();
    const user = JSON.parse(localStorage.getItem('user'));
    const swiperRef = useRef();
    const [loading, setLoading] = useState(true);
    const [featuredAdvisors, setFeaturedAdvisors] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function onPageLoad() {
            setLoading(true);
            const advisorResponse = await consumerProfileService.getassessmentResults(user.id);
            setFeaturedAdvisors(advisorResponse.advisors);
            setLoading(false);
            advisorResponse.advisors.forEach((advisor) => {
                mixpanelTrack('Advisor Displayed', 'Data', {
                    'Advisor Name': `${advisor.firstName} ${advisor.lastName} - ${advisor.professionalId}`,
                    Screen: 'Assessment Results',
                });
            });
        }
        onPageLoad();
    }, [user.id]);

    return (
        <>
            {loading && (
                <div className='spinnerContainer'>
                    <ClipLoader color={'var(--adfi_blue500)'} loading={loading} size={100} />
                </div>
            )}
            <div className={styles.pageContainer}>
                <div className={styles.contentContainer}>
                    {useIsScreenWidth(600) && (
                        <div className={styles.assessmentResultsHeader}>
                            {!loading && (
                                <>
                                    <h1>Assessment Results</h1>
                                    <span style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                                        <h2>Based on your input, the following advisors fit your search criteria. Review their profiles to learn more about them and the services they provide.</h2>
                                        <span style={{ display: 'flex', gap: '10px' }}>
                                            <Button onClick={() => swiperRef.current.slidePrev()} className='clear-button white'>
                                                <ArrowLeft size={20} />
                                            </Button>
                                            <Button onClick={() => swiperRef.current.slideNext()} className='clear-button white'>
                                                <ArrowRight size={20} />
                                            </Button>
                                        </span>
                                    </span>
                                </>
                            )}
                        </div>
                    )}
                    {!useIsScreenWidth(600) && (
                        <div className={styles.assessmentResultsHeader}>
                            {!loading && (
                                <>
                                    <h2 style={{ marginBottom: '10px' }}>
                                        Based on your input, the following advisors fit your search criteria. Review their profiles to learn more about them and the services they provide.
                                    </h2>
                                    <span style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center', flexDirection: 'column' }}>
                                        <h1 style={{ marginBottom: '10px' }}>Assessment Results</h1>
                                        <h2>Swipe to view all results.</h2>
                                    </span>
                                </>
                            )}
                        </div>
                    )}
                    {!loading && (
                        <Swiper
                            modules={[Navigation, Pagination, A11y]}
                            slidesPerView={1.1}
                            className={styles.mySwiper}
                            breakpoints={{
                                600: {
                                    slidesPerView: 1.6,
                                },
                                800: {
                                    slidesPerView: 2.1,
                                    spaceBetween: 20,
                                },
                                1024: {
                                    slidesPerView: 2.5,
                                    spaceBetween: 30,
                                },
                                1500: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                            }}
                            onSwiper={(swiper) => {
                                swiperRef.current = swiper;
                            }}>
                            {featuredAdvisors.map((advisor) => {
                                return (
                                    <SwiperSlide style={{ padding: '5px' }} key={advisor.professionalId}>
                                        <AssessmentCard
                                            advisor={advisor}
                                            onClick={() =>
                                                mixpanelTrack('Advisor Profile Clicked', 'Data', {
                                                    'Advisor Name': `${advisor.firstName} ${advisor.lastName} - ${advisor.professionalId}`,
                                                    Screen: 'Assessment Results',
                                                })
                                            }
                                        />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    )}
                    {!loading && (
                        <div className={styles.marketplaceFooter}>
                            <span>
                                <b>Head to the marketplace</b> to view all financial advisors.
                            </span>{' '}
                            <Button color='clear-button white' size='thin' onClick={() => navigate(`/marketplace`, { replace: true })}>
                                Go to Marketplace
                                <ArrowRight size={20} />
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default AssessmentResults;
//style={ {display: "flex", justifyContent: "center"}}
