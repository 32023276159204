import React, { useState, useEffect } from "react";
import styles from "./PricingForm.module.scss";
import { Check } from 'react-feather';
import Button from "../../Button/Button";
import advisorProfileService from "../../../services/advisorProfile.service";
import ErrorBanner from "../../ErrorBanner/ErrorBanner";

export default function PricingForm(props) {

    const data = props.data;
    const setData = props.setData;
    const pricingOptions = props.pricingOptions
    const columnType = props.columnType;

    const [pricing, setPricing] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [errors, setErrors] = useState({});
    const [showErrorBanner, setShowErrorBanner] = useState(false);

    const firmAdminAuth = props.firmAdminAuth;

    useEffect(() => {
        const defaultPricing = setDefaultPricing();
        setPricing(defaultPricing);
    }, [pricingOptions])



    const handleCheck = (event) => {
        let updatedChecked = [...pricing];
        if (event.target.checked) {
            const objIndex = updatedChecked.findIndex((obj => obj.value === event.target.value));
            updatedChecked[objIndex].checked = true;
        } else {
            const objIndex = updatedChecked.findIndex((obj => obj.value === event.target.value));
            updatedChecked[objIndex].checked = false;
        }
        setPricing(updatedChecked);
    };



    function cancelButton() {
        const defaultPricing = setDefaultPricing();
        setPricing(defaultPricing);
        props.onClose();
    }

    function setDefaultPricing() {
        let tempOptions = pricingOptions;
        tempOptions.forEach(option => {
            option.checked = false;
            option.disabled = false;
        });
        pricingOptions?.forEach((option) => {
            data?.forEach((item) => {
                if (item.value === option.value) {
                    const objIndex = tempOptions.findIndex((obj => obj.value === item.value));
                    tempOptions[objIndex].checked = true;
                    tempOptions[objIndex].disabled = false;
                }
            })
        })
        return tempOptions;
    }


    async function onSubmit() {
        setSubmitDisabled(true)
        setShowErrorBanner(false);

        const checked = pricing.filter((elements) => elements.checked)

        let columnData;
        if (columnType === 'pricing') {
            columnData = [
                {
                    pricing: checked
                }
            ];
        } else {
            columnData = [
                {
                    assetRanges: checked
                }
            ];
        }

        const myInit = {
            body: {
                columnData
            }
        };

        try {
            await Promise.all([advisorProfileService.update(props.advisor.professionalId, myInit, firmAdminAuth), props.updateCompletenessScore()]);
            setSubmitDisabled(false)
            setData(checked)
            props.onClose();
        } catch (error) {
            setSubmitDisabled(false);
            setErrors(error.toString());
            setShowErrorBanner(true);
            console.log(error);
        }

    }



    return (
        <>
            <div className={styles.formContainer}>
                {pricing?.map((option, index) => (
                    <label className={styles.optionRow} key={index}>
                        <input value={option.value} checked={option.checked} disabled={option.disabled} onChange={handleCheck} type="checkbox" />
                        <span className={styles.checkmark}></span>
                        <span>{option.value}</span>
                    </label>
                ))}

            </div>
            {showErrorBanner && <ErrorBanner onClick={() => setShowErrorBanner(false)}>{errors}</ErrorBanner>}

            <div className="modal-footer">
                <Button className="modal-cancel-button" color="clear-button" onClick={() => cancelButton()}>Cancel</Button>
                <Button className="modal-cancel-button" color="gradient-button" onClick={onSubmit} disabled={submitDisabled}><Check size={20} />Confirm</Button>
            </div>
        </>
    )
}
