import { Home, Briefcase, Heart, Cpu, PenTool, Shield, DollarSign, Umbrella } from 'react-feather';

export const HELP_WITH_FILTERS = [
    { key: 1, value: "Saving Up For A House", icon: "/buying-home.png" },
    { key: 2, value: "Supporting My Parents", icon: "/supporting-parents.png" },
    // { key: 3, value: "Single Parent Finances", icon: "/single-parent.png" },
    { key: 4, value: "Navigating Divorce", icon: "/divorce.png" },
    { key: 5, value: "Managing Debt", icon: "/debt.png" },
    { key: 6, value: "Equity Compensation", icon: "/equity-compensation.png" },
    { key: 7, value: "Real Estate Investing", icon: "/real-estate-investing.png" },
    { key: 8, value: "Education Cost Planning", icon: "/education-savings.png" },
    { key: 9, value: "Financial Windfall", icon: "/inheritance.png" },
    { key: 10, value: "Reducing My taxes", icon: "/reduce-my-taxes.png" },
    { key: 11, value: "Planning For Retirement", icon: "/planning-for-retirement.png" },
    { key: 12, value: "Insurance Planning", icon: "/insurance-planning.png" },
    { key: 13, value: "Creating An Estate Plan", icon: "/create-an-estate-plan.png" }
];

export const INDUSTRY_MAPPINGS = [
    { key: 1, value: "Real Estate", icon: 'home' },
    { key: 2, value: "Business", icon: 'briefcase' },
    { key: 3, value: "Healthcare", icon: 'heart' },
    { key: 4, value: "Tech", icon: 'cpu' },
    { key: 5, value: "Legal", icon: 'pentool' },
    { key: 6, value: "Military", icon: 'shield' },
    { key: 7, value: "Finance", icon: 'dollarsign' },
    { key: 8, value: "Other", icon: 'umbrella' }
];

export const ICON_MAPPINGS = [
    { key: "home", icon: <Home size={20} /> },
    { key: "briefcase", icon: <Briefcase size={20} /> },
    { key: "heart", icon: <Heart size={20} /> },
    { key: "cpu", icon: <Cpu size={20} /> },
    { key: "pentool", icon: <PenTool size={20} /> },
    { key: "shield", icon: <Shield size={20} /> },
    { key: "dollarsign", icon: <DollarSign size={20} /> },
    { key: "umbrella", icon: <Umbrella size={20} /> }
]

export const LIFESTAGE_MAPPINGS = [
    { key: 1, value: "Early Career" },
    { key: 2, value: "Mid-Career" },
    { key: 3, value: "Pre-Retirement" },
    { key: 4, value: "Young Family" },
    { key: 5, value: "Empty Nesters" },
    { key: 6, value: "Retirement" }
];