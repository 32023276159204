
import { useEffect, useState } from 'react';
import moment from 'moment';
import styles from './AdvisorDashboard.module.scss';
import { useNavigate } from 'react-router';
import advisorProfileService from '../../services/advisorProfile.service';
import ClipLoader from "react-spinners/ClipLoader";
import { Settings, Grid, Calendar, Users, BarChart, ArrowUp, ArrowDown, XCircle, CheckCircle, AlertCircle } from 'react-feather';
import Button from '../../components/Button/Button'
import GlobalFooter from '../../components/GlobalFooter/GlobalFooter';

export default function AdvisorDashboard() {

    const navigate = useNavigate();

    const id = JSON.parse(localStorage.getItem('user')).id;

    const [loading, setLoading] = useState(true);
    const [meetingRequests, setMeetingRequests] = useState([])
    const [leads, setLeads] = useState([]);
    const [ascending, setAscending] = useState(false);
    const [isDesktop, setDesktop] = useState(window.innerWidth > 1040);



    useEffect(async () => {
        if (!JSON.parse(localStorage.getItem('user')).groups.includes('Advisor') || !id) {
            navigate(`/404`, { replace: true });
        } else {
            try {
                let tempMeetingRequests = await advisorProfileService.getMeetingRequests(id);
                tempMeetingRequests.sort(function (a, b) {
                    if (!a.createdDate) {
                        return 1;
                    }
                    if (!b.createdDate) {
                        return -1;
                    }
                    if (a.createdDate === b.createdDate) {
                        return 0;
                    }
                    return new Date(b.createdDate) - new Date(a.createdDate);
                });
                setMeetingRequests(tempMeetingRequests);
                let tempLeads = await advisorProfileService.getLeads(id);
                tempLeads.sort(function (a, b) {
                    if (!a.timestamp) {
                        return 1;
                    }
                    if (!b.timestamp) {
                        return -1;
                    }
                    if (a.timestamp === b.timestamp) {
                        return 0;
                    }
                    return new Date(b.timestamp) - new Date(a.timestamp);
                });
                setLeads(tempLeads);
            } catch (e) {
                console.log(e);
                // error handling
            }
        }
        setLoading(false);
        window.addEventListener("resize", updateMedia);

        return () => window.removeEventListener("resize", updateMedia);
    }, []);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 1040);
    };

    useEffect(() => {
        let tempMeetings = [...meetingRequests];
        if (tempMeetings.length > 0) {
            if (ascending) {
                tempMeetings.sort(function (a, b) {
                    return new Date(a.createdDate) - new Date(b.createdDate);
                });
            } else {
                tempMeetings.sort(function (a, b) {
                    return new Date(b.createdDate) - new Date(a.createdDate);
                });
            }
            setMeetingRequests(tempMeetings);
        }
    }, [ascending])

    function displayMeetingTimeLocal(time) {
        if (!time) {
            return '';
        }
        time = time?.replaceAll('-', '/');
        const utcTime = `${time} UTC`
        return new Date(utcTime).toLocaleString('en-US', { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
    }

    function displayXTimeAgo(date) {
        date = date?.replaceAll('-', '/');
        let now = moment(new Date());
        const momentDate = moment(new Date(`${date} UTC`));
        let minutes = now.diff(momentDate, "minutes");
        let hours = now.diff(momentDate, "hours")
        let days = now.diff(momentDate, "days")
        let weeks = now.diff(momentDate, "weeks")
        let months = now.diff(momentDate, "months")

        if (months > 12) {
            let years = Math.floor(months / 12);
            let extraMonths = months % 12;
            return years > 1 ? `${years} years, ${extraMonths} months ago` : `${years} year ago`;
        } else if (months > 0) {
            return months > 1 ? `${months} months ago` : `${months} month ago`;
        } else if (weeks > 0) {
            return weeks > 1 ? `${weeks} weeks ago` : `${weeks} week ago`;
        } else if (days > 0) {
            return days > 1 ? `${days} days ago` : `${days} day ago`;
        } else if (hours > 0) {
            return hours !== 1 ? `${hours} hours ago` : `${hours} hour ago`;
        } else {
            return minutes !== 1 ? `${minutes} minutes ago` : `${minutes} minute ago`;
        }
    }

    function renderTimeFilter() {
        if (ascending) {
            return <span className={styles.timeSortButton}><ArrowUp color="var(--adfi_blue500)" size={16} />Ascending</span>
        }
        return <span className={styles.timeSortButton}><ArrowDown color="var(--adfi_blue500)" size={16} />Descending</span>

    }

    function renderStatus(meeting) {
        if (meeting.isThirdParty) {
            return ''
        }
        if (meeting.status === 'unverified') {
            return <span className={styles.timeSortButton}><AlertCircle color="var(--adfi_blue500)" size={16} />Pending</span>
        } else if (meeting.status === 'approved') {
            return <span className={styles.timeSortButton}><CheckCircle color="var(--adfi_green500)" size={16} />Approved</span>
        } else if (meeting.status === 'denied') {
            return <span className={styles.timeSortButton}><XCircle color="var(--adfi_red500)" size={16} />Denied</span>
        }
    }

    function renderMeetingTitle(meeting) {

        if (meeting.isThirdParty) {
            return `Meet with ${meeting?.consumerName ?? "User"} via 3rd Party Scheduling Platform`
        }
        if (meeting.meetingFormat === 'advisorPhoneNumber') {
            return `${meeting.consumerName ?? "User"} will call you`
        }
        else if (meeting.meetingFormat === 'advisorEmail') {
            return `${meeting.consumerName ?? "User"} will email you`
        }
        else if (meeting.meetingFormat === 'consumerPhoneNumber') {
            return `Call ${meeting.consumerName ?? "User"} at ${meeting.consumerPhoneNumber}`
        } else if (meeting.meetingFormat === 'consumerEmail') {
            return `Email ${meeting.consumerName ?? "User"} at ${meeting.consumerEmail}`
        }
        else {
            return `Meet with ${meeting.consumerName ?? "User"}`
        }
    }


    return (
        <>
            {loading && <div className='spinnerContainer'>
                <ClipLoader color={'var(--adfi_blue500)'} loading={loading} size={100} />
            </div>}



            <div className={`${styles.dashboardContainer} ${styles.sideNavMargin}`}>

                <div className={styles.profileHeader}>
                    <Grid size={24} color={'var(--adfi_blue500)'} />
                    <div className={styles.profileHeaderText}>Dashboard</div>
                    <div className={styles.rightSideButtons}>
                        <Button color="gradient-button" onClick={() => navigate('/account-settings')}><Settings size={20} />{isDesktop && <>Settings</>}</Button>
                    </div>
                </div>

                <div className={styles.statisticsContainer}></div>
                <div className={styles.infoContainer}>
                    {meetingRequests && <div className={styles.meetingRequestContainer}>
                        <div className={styles.meetingHeader}><Users size={16} />Visitors ({leads.length})</div>
                        <div className={styles.scroll}>
                            {leads.map((lead, index) => (
                                <div className={styles.meetingRequest} key={index}>
                                    <div className={styles.meetingInfo}>
                                        <div className={styles.consumerName}><div className={styles.rectangle} />{lead.firstName ?? 'User'}</div>
                                        {lead.jobTitle &&<div>{lead.jobTitle} {lead.placeOfEmployment && <span>at {lead.placeOfEmployment}</span>}</div>}
                                        <div>visited your profile</div>
                                    </div>
                                    <div className={`${styles.meetingInfo} ${styles.meetingTime}`}>
                                        <div className={styles.meetingTime}>{displayXTimeAgo(lead.timestamp)}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>}

                    {meetingRequests && <div className={styles.meetingRequestContainer}>
                        <div className={styles.meetingHeader}><Calendar size={16} />Requested Meetings<span className={styles.timeSort}><BarChart size={16} />Time</span><span onClick={() => setAscending(!ascending)}>{renderTimeFilter()}</span></div>
                        <div className={styles.scroll}>
                            {meetingRequests.map((meeting, index) => (
                                <div className={styles.meetingRequest} key={index}>
                                    <div className={styles.meetingInfo}>
                                        <div className={styles.consumerName}><div className={styles.rectangle} /><span>{renderMeetingTitle(meeting)}</span></div>
                                        <div>{meeting.meetingFocus}</div>
                                    </div>
                                    <div className={`${styles.meetingInfo} ${styles.meetingTime}`}>
                                        <div className={styles.meetingTime}>{displayMeetingTimeLocal(meeting.createdDate)}</div>
                                        <div>{renderStatus(meeting)}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>}
                </div>

            </div>
            {!loading && <GlobalFooter background={true} />}
        </>
    )

}