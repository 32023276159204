import validator from 'validator';

function validateConfirmInfo(values) {
    let errors = {};

    if (!validator.matches(values.companyName, "^[a-zA-Z-,.' -]*$")) {
        errors.companyName = 'Enter valid company name'
    }

    if (!validator.matches(values.jobTitle, "^[a-zA-Z-,.' -]*$")) {
        errors.jobTitle = 'Enter valid job title'
    }

    if (!validator.matches(values.city, "^[a-zA-Z-,.' -]*$")) {
        errors.city = 'Enter valid email'
    }

    if (!values.state) {
        errors.state = 'Please select a state'
    }

    if (!validator.isNumeric(values.yearsOfExperience)) {
        errors.yearsOfExperience = 'Enter a valid numeric value'
    }

    if (!validator.isNumeric(values.averageAccountSize)) {
        errors.averageAccountSize = 'Enter a numeric value, no special characters'
    }

    if(values.AUM) {
        if (!validator.isNumeric(values.AUM)) {
            errors.AUM = 'Enter a numeric value, no special characters'
        }
    }
    
    if (values.numberOfClients) {
        if (!validator.isNumeric(values.numberOfClients)) {
            errors.numberOfClients = 'Enter a numeric value, no special characters'
        }    
    }

    return errors;
}

export default validateConfirmInfo;